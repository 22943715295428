import React, { useReducer, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import reducer from '../../util/reducer';
import useIntl from '../../hooks/useIntl';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import { useAlertDialog } from '../../context/AlertDialogProvider';
import useSentry from '../../hooks/useSentry';
import TMDialog from '../../lib/Dialog/TMDialog';
import TMForm from '../../lib/Form/TMForm';
import TMAutocomplete from '../../lib/Input/TMAutocomplete';
import client from '../../feathers';

const initialState = {
  list: [],
  output: [],
  outputJSON: {},
  carrier: undefined,
  files: [],
  fileRefs: [],
  carriers: [],
};

const RequestForReleaseDialog = ({
  open,
  handleDialogClose,
  user,
  fetchRequestsForRelease
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { translate } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const logSentry = useSentry();

  // Prefill carriers
  useEffect(() => {
    const getCarriers = async() => {
      try {
        const carriers = await client.service('organizations').find({ query: { role: 'carrier' } });
        dispatch({ payload: { carriers: carriers.data.map(c => ({ value: c.address, label: c.name, target: { name: 'carrier', value: c.address } }) ) } });
      } catch (error) {
        logSentry(error);
        showAlert(error.message);
      }
    }
    getCarriers();
  }, []);

  useEffect(() => {
    const validateInput = async () => {
      let output = [];
      let outputJSON = {};
      let buffer = '';

      for (var i = 0; i < state.list.length; i++) {
       let c = state.list[i];
       // Check whether we have a number or character
       if (c.match(/[a-z]/i) || c.match(/[0-9]/)) {
         // console.debug("1", c)
         buffer = buffer + c;
       }
       // Check whether we have a separator
       if (c.match(/[\-,;\t\r\n]/) && buffer.length > 0) {
         // console.debug("2", c)
         output.push(buffer);
         buffer = '';
       }
       // Check whether we have a space
       // If the buffer is larger than 6, we treat the space as a separator, otherwise we ignore it
       if (c.match(/ /) && buffer.length > 6) {
         // console.debug("3", c)
         output.push(buffer);
         buffer = '';
       }
      }
      // Push any remaining buffer content into the output array
      if (buffer.length > 0) {
       output.push(buffer);
      }

      // containernummers hebben een prefix van max vier letters, bl's hebben een prefix die langer is
      outputJSON = output.map(entry => entry.match(/^[a-z]{5,}/i) ? ({bl: entry}) : ({ct: entry}))
      dispatch({ payload: { output, outputJSON } });
    };

    validateInput();
  }, [state.list]);

  const resetForm = () => dispatch({ payload: {
    list: [],
    output: [],
    outputJSON: {},
    carrier: undefined,
    files: [],
    fileRefs: []
  }});

  const resetAndClose = () => {
    window.addToDataLayer('request-for-release', 'close');
    handleDialogClose(resetForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ payload: { [name]: value } });
  };

  const handleFileRefs = (fileName, e) => {
    const existingFileRefs = [...state.fileRefs];
    let existingFileRef = existingFileRefs.find(ref => ref.name === fileName)
    existingFileRef.value = e.target.value;
    dispatch({ payload: { fileRefs: existingFileRefs } });
  }

  const handleFilesChange = (files) => {
    let existingFiles = [...state.files];
    let existingFileRefs = [...state.fileRefs];

    if (files.length > existingFiles.length) {
      // a file was added
      const addedFiles = files.filter(f => !existingFiles.includes(f));
      // console.debug("Files were added", addedFiles)
      existingFileRefs.push( ...addedFiles.map(f => ({name: f.name, value: undefined})) );
    }
    if (files.length < existingFiles.length) {
      // a file was removed
      const removedFile = existingFiles.filter(f => !files.includes(f));
      // console.debug("A file was removed", removedFile)
      existingFileRefs.filter(ref => ref.name !== removedFile.name);
    }

    dispatch({ payload: { files, fileRefs: existingFileRefs } });
  };

  const handleRequestForRelease = async () => {
    try {
      showProgressDialog();
      // save entry in db
      await client.service('requests-for-release').create({
        inputRaw: state.list,
        inputJSON: state.outputJSON,
        carrierAddress: state.carrier,
        files: state.files.map(f => f.name),
        fileReferences: state.fileRefs,
        organizationAddress: user.organizationAddress,
        createdAt: new Date(),
      })

      enqueueSnackbar(translate("req.for.release.created"), { autoHideDuration: 3000 });
      fetchRequestsForRelease && await fetchRequestsForRelease();
    } catch (error) {
      logSentry(error);
      showAlert(error.message);
    } finally {
      hideProgressDialog();
      resetAndClose();
    }
  }

  let fields = [
    { field: 'list', label: translate("req.for.release.list.label"), required: true, rows: 10, multiline: true, fullWidth: true, helperText: translate("req.for.release.list.helper") },
    { field: 'carrier', label: translate("req.for.release.carrier.label"), required: true, componentType: TMAutocomplete, suggestions: state.carriers, fullWidth: true },
    { field: 'files', label: "Upoad files", componentType: DropzoneArea, fullWidth: true },
  ].concat(state.files.map((file, index) => (
    { field: `fileRefs`, index: index, label: 'File reference', placeholder: 'File reference', handleChange: (e) => handleFileRefs(file.name, e), fullWidth: true, helperText: `File reference for ${file.name}` }
  )))

  return (
    <TMDialog
      key="request-for-release"
      title={translate("req.for.release.title")}
      dialogOpen={open}
      handleDialogClose={resetAndClose}
      showBottomClose={false}
    >
      <TMForm
        object={state}
        handleChange={handleChange}
        handleFilesChange={handleFilesChange}
        handleSubmit={handleRequestForRelease}
        submitLabel={translate('req.for.release.submit')}
        enableSubmitCondition={state.list.length > 0 && !!state.carrier}
        handleCancel={resetAndClose}
        cancelLabel={translate('general.cancel')}
        fields={fields}
      >
        

      </TMForm>
    </TMDialog>
  );
};

export default RequestForReleaseDialog;
