import React from 'react'
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth'
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = (props) => {
  const { accessToken } = useAuth();
  if (accessToken) {
    if ((/fwd=/).test(props.location.search)) {
      const fwd = props.location.search.substring(props.location.search.indexOf("fwd=")+4);
      return <Redirect push to={fwd} />
    } else {
      return <Redirect push to={`/${props.location.search}`} />
    }
  }

  return (
    <div style={{ display: 'flex', height: '60vh', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={100} thickness={5} />
    </div>
  )
};

export default Loader;