import React, { useState }  from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react'
import { mdiMicrosoftExcel as ExcelIcon } from '@mdi/js';
import FilterListIcon from '@material-ui/icons/FilterList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { saveAs } from 'file-saver';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Http } from '../axios';
import { getExcelWorkbook } from '../util/excel';
import getPincode from '../util/pincode';
import { useHistory } from 'react-router-dom';
import useWalletError from '../hooks/useWalletError';
import useErrorDrawer from '../hooks/useErrorDrawer';
import useAuth from '../hooks/useAuth';
import useIntl from '../hooks/useIntl';
import useConfirmDialog from '../hooks/useConfirmDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { CreateReleaseButton } from './carrier-ui/CreateReleaseButton';
import { fetchTermsAndConditions } from '../util/terms-and-conditions';
import { isTokenValid } from '../util/token';
import BulkOperationsButton from './bulk-operations/BulkOperationsButtons';
import RequestForReleaseButton from './request-for-release/RequestForReleaseButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useFlatExcel from '../hooks/useFlatExcel';
import useSentry from '../hooks/useSentry';
import NMoTDialog from './NMoT/NMoTDialog';

const useStyles = makeStyles(() => ({
  icon_hover: {
    '&:hover': {
      color: '#cda24d',
    },
  },
}));

const DownloadButtons = ({
  onFilterClick,
  filter = {},
  search,
  refreshBills,
  doTransfer,
  anonAddresses,
}) => {
  const classes = useStyles();
  const { translate } = useIntl();
  const onWalletError = useWalletError();
  const onError = useErrorDrawer();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const [NMoTShowDialog, setNMotShowDialog] = useState(false);
  const [NMoTSelected, setNMotSelected] = useState('');
  const [NMoTReleases, setNMotReleases] = useState([]);

  const flatExcelExport = useFlatExcel();
  const logSentry = useSentry()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOk = (type) => download(type, true);
  const onCancel = (type) => download(type, false);

  const onClick = (type) => {
    if (!user.features.canTransferRevoke) onOk(type);
    else showConfirmDialog(type);
  };

  const { showConfirmDialog } = useConfirmDialog(
    'pincode.confirm.title',
    'pincode.confirm.text',
    onOk,
    onCancel,
    'getpincode.fetch.confirm',
    'getpincode.fetch.skip',
    true
  );

  const getTermsAndConditions = async (blNumber) => {
    const { data } = await fetchTermsAndConditions(blNumber, anonAddresses);
    return data;
  };

  const fetchReleases = async () => {
    return await Http.post('/releases/download/get', {
      filter: JSON.stringify(filter),
      search,
      // too big for headers or params, could trigger a 413 Entity Too Large response error (SCRDEV-421)
      includeadr: anonAddresses.join(','), 
     });
  };

  const download = async (fileType, fetchPincode) => {
    let bills = [];
    let promises = [];
    // We're going to use the wallet here to get the pincode, so check the token already here
    if (fetchPincode && !isTokenValid()) {
      onWalletError(new Error(translate('session.expired')));
      history.push('/');
      return;
    }

    try {
      showProgressDialog();
      let { data } = await fetchReleases();

      if (!data.length) {
        throw new Error('No data found.');
      }

      if (user.features.canNmot && fetchPincode) {
        setNMotReleases(data.map(r => r.address));
        setNMotShowDialog(true);
      }
      
      if (fetchPincode) {
        enqueueSnackbar(
          <div>
            {translate('pincode.retrieve.pins.progress')}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </div>,
          { persist: true }
        );
      }

      if (fileType === 'flat-xlsx') {
        await flatExcelExport(data, fetchPincode);
      } else {
        data.forEach((bill) => {
          let getBillData = async () => {
            let terms = await getTermsAndConditions(bill.blNumber);
            let releases = await getReleases(bill, fetchPincode);

            return {
              blNumber: bill.blNumber,
              terms,
              releases,
            };
          };
          promises.push(getBillData);
        });

        for (let index = 0; index < promises.length; index++) {
          let bill = await promises[index]();
          bills.push(bill);
        }

        if (fileType === 'pdf') {
          const downloadResponse = await Http.post(
            '/releases/download', 
            {  fetchPincode, bills }, 
            { responseType: 'blob' }
          );

          const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));

          const link = document.createElement('a');
          link.href = url;
          link.id = 'pdf-id';
          link.setAttribute('download', `Releases.${fileType}`);
          document.body.appendChild(link);
          link.click();
        }

        if (fileType === 'xlsx') {
          const workbook = getExcelWorkbook(bills, fetchPincode);
          const buffer = await workbook.xlsx.writeBuffer();
          saveAs(new Blob([buffer]), 'Releases.xlsx');
        }
      }
    } catch (e) {
      logSentry(e);
      if (e.response) {
        onError(e.response.status, e.response.statusText, translate('general.error'));
      } else {
        onError(500, e.message, translate('general.error'));
      }
    } finally {
      if(fetchPincode) { 
        closeSnackbar();
        refreshBills();
      }
      hideProgressDialog();
    }
  };

  const getReleases = async (bill, fetchPincode) => {
    try {
      const pincodeResults = fetchPincode ? await getPincode(bill.releases.map(({address, version}) => ({address, version})), user.organization) : [];

      return bill.releases.map((release) => {
        let pincodeResult = pincodeResults.find(res => res.address === release.address)
        return {
          ...release,
          releaseStatus: translate(`releaseStatus.${release.releaseStatus}`),
  	      pincode: pincodeResult ? (pincodeResult.pincode ? pincodeResult.pincode : pincodeResult.error) : undefined
        };
      });
    } catch (e) {
      onWalletError(translate(e.message));
    }
  };

  return (
    <React.Fragment>
      <Tooltip title={translate('dataTable.controls.filterTable')}>
        <IconButton
          aria-label="filter"
          onClick={() => { onFilterClick(); window.addToDataLayer('filter', 'open'); }}
          className={classes.icon_hover}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {user.organizationRole !== 'carrier' && (
        <React.Fragment>
          <Tooltip title={translate('dataTable.controls.pdf')}>
            <IconButton
              aria-label="download"
              className={classes.icon_hover}
              onClick={() => { onClick('pdf'); window.addToDataLayer('download', 'pdf'); }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={translate('dataTable.controls.downloadExcel')}>
            <IconButton
              aria-label="excel"
              aria-controls="excel-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e); window.addToDataLayer('download', 'excel'); }}
              className={classes.icon_hover}
            >
              <Icon path={ExcelIcon} size={1} color='grey'/>
            </IconButton>
          </Tooltip>
          <Menu
            id="excel-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { onClick('xlsx'); window.addToDataLayer('download', 'xlsx'); }}>Grouped by BL</MenuItem>
            <MenuItem onClick={() => { onClick('flat-xlsx'); window.addToDataLayer('download', 'flat-xlsx');}}>Flat table</MenuItem>
          </Menu>
        </React.Fragment>
      )}

      { user.organizationRole === 'carrier' && (
        <CreateReleaseButton
          classes={classes}
          user={user}
          refreshBills={refreshBills}
          doTransfer={doTransfer}
        />
      )}
      
      { user.features.canRequestForRelease && (
        <RequestForReleaseButton
          user={user}
        />
      )}

      { user.organizationRole !== 'carrier' && (
        <BulkOperationsButton
          classes={classes}
          user={user}
          refreshBills={refreshBills}
          doTransfer={doTransfer}
        />
      )}

      <NMoTDialog 
        show={NMoTShowDialog}
        selected={NMoTSelected}
        selectedReleaseAddresses={NMoTReleases} 
        onSelection={(selected) => { setNMotSelected(selected) }} 
        // onCancel={() => setNMotShowDialog(false)}
        onCancel={(event, reason) => { if (!['escapeKeyDown', 'backdropClick'].includes(reason)) { setNMotShowDialog(false) } }}
      />

    </React.Fragment>
  );
};

export default DownloadButtons;
