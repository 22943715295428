import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';

class TMMenu extends React.Component {
  render() {
    const {
      id,
      handleMenuClose,
      anchorEl,
      align = 'right',
      children,
    } = this.props;

    return (
      <Menu
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: align }}
        transformOrigin={{ vertical: 'top', horizontal: align }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {children}
      </Menu>
    );
  }
}

TMMenu.propTypes = {
  id: PropTypes.string.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  anchorEl: (props, propName, componentName) => {
    if (props[propName] === undefined) {
      return new Error(`Missing anchorEl Prop in ${componentName}`);
    }

    const value = props[propName];
    if (value !== null && typeof value !== 'object') {
      return new TypeError(
        `Invalid Prop value: ${value} for ${propName} in ${componentName}`
      );
    }
    return null;
  },
  align: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ),
};

export default TMMenu;
