import React from 'react'
import useErrorDrawer from '../../../hooks/useErrorDrawer'
import useIntl from '../../../hooks/useIntl'

const useCarrierApiError = () => {

  const onError = useErrorDrawer()
  const { translate } = useIntl()
  
  const onCarrierApiError = (e) => {
		let eMessage = e.message.startsWith('carrierui') ? translate(e.message) : e.message;

		let status = 500;

		if (e.response) {
	    eMessage = e.response.statusText;
	    status = e.response.status;
  	
    	if (status === 401 && (eMessage === undefined || eMessage === '' || eMessage === 'Unauthorized')) {
  			eMessage = translate('carrierui.authentication.tokenexpired');
  		}
		}

    const message = (
    	<div>
        {status === 401 ? translate('carrierui.authentication.message') : translate('carrierui.error.message')}
        {e && <div style={{fontFamily: 'monospace', marginTop: 10, padding: 10, backgroundColor: '#eee'}}>{`${status}: ${eMessage}`}</div>}
    	</div>
  	)

    onError(status, message, translate('carrierui.error.title'), 'carrierApi.html')
  }

  return onCarrierApiError
}

export default useCarrierApiError