import React, { useEffect, useReducer } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Box,
} from '@material-ui/core';
import useIntl from '../hooks/useIntl';
import OrganizationSelectionTable from './OrganizationSelectionTable';
import reducer from '../util/reducer';
import { getConnectedOrganizations } from '../util/connections';

const initialState = {
  selectedOrganization: null,
  connectedOrganizations: [],
  isShowDialog: false,
};

const TransferRevokeDialog = ({
  onTransfer,
  onRevoke,
  selectedReleaseAddresses,
  onCancel,
  show,
  type = 'transfer',
}) => {
  const { translate } = useIntl();

  const [
    { selectedOrganization, connectedOrganizations },
    dispatch,
  ] = useReducer(reducer, initialState);

  const title = translate(`transferRevoke.${type}.dialog.title`);

  const getContent = (organizations) => (
    <div>
      {translate(`transferRevoke.${type}.dialog.line_1`, {
        numberOfReleases: selectedReleaseAddresses.length || 0,
      })}
      {type === 'transfer' && (
        <Box my="1rem">
          <OrganizationSelectionTable
            organizations={organizations}
            onSelect={onOrganizationSelect}
          />
          <p>
            {translate(`transferRevoke.transfer.dialog.line_2`)}
          </p>
        </Box>
      )}
    </div>
  );

  const actions = (
    <div style={controlsStyle}>
      <Button variant="contained" color="secondary" onClick={() => { onCancel();  dispatch({ payload: { selectedOrganization: null } }); window.addToDataLayer('transfer-dialog', 'cancel'); }}>
        {translate('general.cancel')}
      </Button>
      {type === 'transfer' && (
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedOrganization}
          onClick={() => { onTransfer(selectedOrganization); window.addToDataLayer('transfer-dialog', 'transfer'); }}
        >
          {translate('blActions.transfer')}
        </Button>
      )}
      {type === 'revoke' && (
        <Button variant="contained" color="primary" onClick={() => { onRevoke(); dispatch({ payload: { selectedOrganization: null } }); window.addToDataLayer('transfer-dialog', 'revoke'); }}>
          {translate('general.ok')}
        </Button>
      )}
    </div>
  );

  const onOrganizationSelect = (selectedOrganization) =>
    dispatch({ payload: { selectedOrganization } });


  useEffect(() => {
    async function fetchData() {
      const connections = await getConnectedOrganizations();
      dispatch({ payload: {
        connectedOrganizations: connections.connectedOrganizations
      }})     
    }
    fetchData();
  }, [])

  return (
    <Dialog open={show}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{getContent(connectedOrganizations)}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default TransferRevokeDialog;

const controlsStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: 10,
};
