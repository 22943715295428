import React from 'react';
import _ from 'lodash';
import client from '../feathers';
import TransferRevokeReport from '../components/TransferRevokeReport';
import useIntl from './useIntl';
import useAuth from './useAuth';
import useWalletError from './useWalletError';
import useSentry from './useSentry';
import { useAlertDialog } from '../context/AlertDialogProvider';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { isTokenValid } from '../util/token';
import { parseError, beforeUnloadListener } from '../util/release';

export default (
  defaultReleaseAddresses,
  refreshBills,
  anonAddresses,
  dispatch
) => {
  const { translate } = useIntl();
  const { user } = useAuth();
  const { showAlert } = useAlertDialog();
  const onWalletError = useWalletError();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const logSentry = useSentry();

  const onTransferRevoke = async (
    type = 'transfer',
    target_organization,
    selectedReleaseAddresses = defaultReleaseAddresses,
    isBatch = false,
    bulkValidationErrors
  ) => {

    let data, admin, error = '';
    let results = [];

    const sortResults = (a, b) => {
      if (a.result?.billOfLading && a.result?.container) {
        if (a.result.billOfLading.blNumber > b.result.billOfLading.blNumber) return 1;
        if (a.result.billOfLading.blNumber < b.result.billOfLading.blNumber) return -1;
        if (a.result.container.containerNumber > b.result.container.containerNumber) return 1;
        if (a.result.container.containerNumber < b.result.container.containerNumber) return -1;
      }
      if (a.container) {
        if (a.blNumber > b.blNumber) return 1;
        if (a.blNumber < b.blNumber) return -1;
        if (a.container.containerNumber > b.container.containerNumber) return 1;
        if (a.container.containerNumber < b.container.containerNumber) return -1;
      }
      if (a.address) {
        if (a.address > b.address) return 1; 
        if (a.address < b.address) return -1; 
      }
      return 0;
    }

    if (type === 'transfer' && !target_organization) {
      throw new Error(translate('backend.error.missing_target_organization'));
    }

    // We're may be going to use the wallet here to get transfer/revoke, so check the token already here
    if (user.organizationRole !== 'carrier' && !isTokenValid()) {
      onWalletError(new Error(translate('session.expired')));
      this.props.history.push('/');
      return;
    }

    try {
      !isBatch && showProgressDialog(`Processing ${type}...`, `Please do not close your browser until the ${type} has completed. Otherwise, your data will be lost.`);
      dispatch({ type: 'SET', payload: { showTransferRevokeDialog: false } });

      if (user.organizationRole === 'carrier') {
        if (type === 'transfer') {
          // get the admin user
          let users = await client.service('users').find({
            query: {
              organization_address:
                target_organization && target_organization.address,
              isAdmin: true,
              $limit: 1,
            },
          });
          if (users.total === 0) {
            throw new Error('Could not find admin user');
          }
          admin = users.data.shift();
        }

console.log(client)
console.log(client.authentication())
console.log(client.service("releases"))


        for (const selectedReleaseAddress of selectedReleaseAddresses) {
          results.push(await client.service('releases').patch(
            selectedReleaseAddress, 
            {
              type: type,
              organizationAddress: target_organization && target_organization.address,
              pkeySign: admin && admin.pkeySign,
            },
            // {
            //   query: { address: { $in: selectedReleaseAddresses }}
            // }
          ));
        }
        // console.log("transfer results", results);
        data = {
          eligible_releases: results,
          not_owned_releases: [],
          pincode_fetched_releases: [],
        };
      } else {
        const service = `${type}s`; //transfers or revokes
        selectedReleaseAddresses = selectedReleaseAddresses.map((address) => ({
          address,
        }));

        // dryRun: only filter out eligible releases
        // it's ok to use target_organization.address here, no private addresses are being recorded yet
        data = await client.service(service).create({
          target_organization_address:
            target_organization && target_organization.address,
          release_addresses: selectedReleaseAddresses,
          ownerAddresses: anonAddresses,
          dryRun: true,
        });

        // We need to make sure the user doesn't close the window during the transfers
        window.addEventListener("beforeunload", beforeUnloadListener);
        console.log("added EventListener");

        // transfer/revoke through wallet
        const eligible_releases_array = data.eligible_releases.map(({address, version}) => ({address, version}));
        let eligible_releases_result = 
          type === 'transfer'

            ? await window.walletApi.transferReleases(
                eligible_releases_array,
                target_organization.reference,
                { from: user.organization, to: target_organization.name }
              )
            : await window.walletApi.revokeReleases(
                eligible_releases_array,
                { from: user.organization }
              );

            
        // need it in the final-clause
        data.eligible_releases.forEach(r => {
          let release = eligible_releases_result.find(res => res.address === r.address);
      		if (release) {
                r.result = release.result;
                r.error = release.error;
      		} else {
      			r.error = "Release not found in result array";
      		}
        });
        
        const processedReleases = eligible_releases_result.filter((r) => !r.error);

        if (processedReleases.length > 0) {
          // include the newly encrypted pincode
          let release_addresses = processedReleases.map((pr) => ({
              address: pr.address,
              encryptedPincode: pr.result.encryptedPincode,
          }));
          
          // no dryRun here, so we need to use the address returned by the transfer
          await client.service(service).create({
            release_addresses,
            target_organization_address: processedReleases[0].result.newOwner,
            ownerAddresses: anonAddresses,
            target_organization_id: target_organization && target_organization.id,
          });

          // warning can be removed now, all is processed
          window.removeEventListener("beforeunload", beforeUnloadListener);
          console.log("removed EventListener");
        }
      }
    } catch (e) {
      
      logSentry(e)

      error = e.message;
    } finally {
      // trigger re-render of the containers
      !isBatch && hideProgressDialog();
      refreshBills(true, false);

      if (error) {
        if (isBatch) {
          return { error };
        } else {
          let content;
          try {
            content = translate('general.error.detail', {
              error: translate(`backend.error.${error}`),
            });
          } catch (err) {
            logSentry(error);
            content = `${translate('general.error')}: ${error}`;
          }
          showAlert(translate(`transfer.${type}.results`), content);
        }
      } else {
        const eligible_releases = data.eligible_releases.filter((r) => !r.error).sort(sortResults);
        const errors = data.eligible_releases.filter((r) => r.error).sort(sortResults);
        const reportData = { ...data, eligible_releases, errors };

        // bulkValidationErrors contain warnings about releases that may have processed successfully.
        // If so, remove said releases from the list of warnings to avoid confusion.
        // To do so, compare bulkValidationErrors with eligible_releases and remove any entries in 
        // bulkValidationErrors that also occur in eligible_releases
        _.pullAllWith(bulkValidationErrors, eligible_releases, (arrVal, othVal) => {
          return arrVal.bl === othVal.blNumber && arrVal.ctr === othVal.container.containerNumber
        })

        if (isBatch) {
          return reportData;
        } else {
          showAlert(
            translate(`transfer.${type}.results`),
            <TransferRevokeReport 
              data={reportData} 
              type={type} 
              bulkValidationErrors={bulkValidationErrors?.sort((a, b) => a.msg > b.msg ? 1 : a.msg < b.msg ? -1 : 0)}
            />
          );
        }
      }
    }
  };

  return { onTransferRevoke };
};

