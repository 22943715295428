function compareStrings(_a, _b) {
  // Assuming you want case-insensitive comparison
  const a = _a.toLowerCase();
  const b = _b.toLowerCase();

  // eslint-disable-next-line no-nested-ternary
  return a < b ? -1 : a > b ? 1 : 0;
}

export const sortByKey = (arr, key) =>
  arr.sort((a, b) => compareStrings(a[key], b[key]));
