/* eslint-disable consistent-return */
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import useIntl from '../hooks/useIntl';
import { ROUTES } from '../pages/routes';
import IFrame from './IFrame';

const SideDrawer = ({
  handleDrawerClose,
  drawerOpen,
  width,
  page,
  component,
  title,
}) => {
  const intl = useIntl();
  const location = useLocation();

  const useStyles = makeStyles((theme) =>
    createStyles({
      drawer: {
        width,
        flexShrink: 0,
      },
      drawerPaper: {
        width,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
      },
    })
  );

  const classes = useStyles();

  let helpPage = page;

  if (!helpPage) {
    const currentRoute = ROUTES.find(
      (route) => `/${route.path}` === location.pathname
    );
    if (!currentRoute) return;

    helpPage = currentRoute.helpPage;
  }

  // SCRDEV-982: keep one, simple helppage
  helpPage = 'simplehelp.html'
  const iframeSrc = `${process.env.PUBLIC_URL}/EN/${helpPage}`;
  if (drawerOpen) {
    window.addToDataLayer('help', helpPage);
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        {title || intl.translate('sideDrawer.header')}
      </div>
      <Divider />
      {component || <IFrame src={iframeSrc} />}
    </Drawer>
  );
};

export default SideDrawer;
