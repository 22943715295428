import React /*, { useReducer }*/ from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import useIntl from '../hooks/useIntl'
import { Button } from '@material-ui/core'

const BlockDialog = ({ show, blockStatus, blockBlNumber, onBlock, onCancel, selectedReleaseAddresses }) => {

    const { translate } = useIntl()

    const title = translate(`block.${blockStatus}.dialog.title`)

    const getContent = () => (
        <div>
            {translate(`block.${blockStatus}.dialog.confirm`, { numberOfReleases: selectedReleaseAddresses.length || 0 })}
        </div>
    )

    const actions = (
        <div style={controlsStyle}>
            <Button variant="contained" color="secondary" onClick={() => onCancel()}>{translate('general.cancel')}</Button>
            {blockStatus === true && <Button variant="contained" color="primary" onClick={() => onBlock(blockStatus, blockBlNumber)}>{translate('blActions.block')}</Button>}
            {blockStatus === false && <Button variant="contained" color="primary" onClick={() => onBlock(blockStatus, blockBlNumber)}>{translate('blActions.unblock')}</Button>}
        </div>
    )

    return (
        <Dialog open={show}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{getContent()}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    )
}

export default BlockDialog

const controlsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: 10,
}
