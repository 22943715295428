import React from 'react';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx'
import TMProfileIconMenu from './TMProfileIconMenu.jsx';
import TMMobileIconMenu from './TMMobileIconMenu.jsx';
import Config from "../../config.json";
import { FormattedMessage } from 'react-intl';
import app from '../../feathers'

const classes = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(14),
  },
  appBar: {
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: '1.5em',
    boxShadow: 'none'
  },
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 20
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonIcon: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  iconSpace: {
    marginRight: theme.spacing(.5)
  },
  menuItem: {
    color: theme.palette.secondary.dark,
    textDecoration: 'none',
  },
  active: {
    color: theme.palette.primary.main,
  },
  supportBox: {
    backgroundColor: '#d6dbdf',
    borderRadius: '15px',
    padding: '10px',
    textAlign: 'center',
    width: '100%',
    lineHeight: '0.8em !important '
  }
});

class TMAppBar extends React.Component {

  state = {
    supportPhone: '',
    supportEmail: '',
  }

  handleLogOut = () => {
    this.props.setAuthenticated(false);
  }

  async componentDidUpdate() {
    if (!this.state.supportEmail && !this.state.supportPhone) {      
      const { organizationAddress } = JSON.parse(window.sessionStorage.getItem('org')) || {}
      if (organizationAddress) {
        const { support_email: supportEmail, support_phone: supportPhone } = await app.service('organizations').get(organizationAddress, { skipIncludes: true })
        // if both are null, skip setState. It triggers an infinite rerender loop and has no value anyway.
        if (supportEmail || supportPhone) {
          this.setState({ supportEmail, supportPhone })
        }
      }
    }
  }

  render() {
    const { classes, isAuthenticated, logoPath = "../tmining-logo.png", className, user } = this.props

    const { supportEmail, supportPhone } = this.state

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, className)}
        >
          <Toolbar>
            <NavLink to={Config.navHomePath}>
              <img src={logoPath} alt="Company logo" style={{ height: '40px' }} />
            </NavLink>
            <div className={classes.grow} />

            <div className={classes.sectionDesktop}>
              {
                isAuthenticated && (supportEmail || supportPhone) &&
                <div className={classes.supportBox}>
                  <p><b>
                    <FormattedMessage id='supportBox.contact' />:</b></p>
                  <p> {[supportPhone, supportEmail].filter(x => x).join(', ')}</p>
                </div>
              }
            </div>

            <TMProfileIconMenu
              classes={classes}
              isAuthenticated={!!isAuthenticated}
              handleHelpClick={this.props.handleHelpClick}
              user={user}
            />

            <div className={classes.sectionMobile}>
              <TMMobileIconMenu
                classes={classes}
                isAuthenticated={isAuthenticated}
                navLinks={Config.navLinks}
              />
            </div>
          </Toolbar>

        </AppBar>
      </div>
    );
  }
}

TMAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setAuthenticated: PropTypes.func,
  logoPath: PropTypes.string,
};

export default withStyles(classes)(TMAppBar);
