/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';

const classes = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    marginBottom: theme.spacing(2),
  },
  input: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: theme.spacing(2),
    fontSize: 16,
  },
  paper: {
    position: 'fixed',
    zIndex: 1,
    marginTop: theme.spacing(1),
  },
});

class TMAutocomplete extends React.Component {
  render() {
    const {
      classes,
      name,
      value,
      suggestions,
      onChange,
      readOnly = false,
      variant = 'outlined',
      placeholder = 'Choose an option',
    } = this.props;

    let options = suggestions;
    const option = suggestions.find((s) => s.value === value);
    if (readOnly) {
      options = option === undefined ? [] : [option];
    }

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            name={name}
            variant={variant}
            classes={classes}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            value={option === undefined ? '' : option}
          />
        </NoSsr>
      </div>
    );
  }
}

TMAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
};

export default withStyles(classes)(TMAutocomplete);
