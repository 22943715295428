import React, { useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const IFrame = ({ src }) => {
  const [initUrl, setInitUrl] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [showBack, setShowBack] = useState(false);

  const iframeRef = React.createRef();

  const goBack = () => {
    iframeRef.current.contentWindow.history.back();
  };

  const onLoad = (event) => {
    const url = event.target.contentWindow.location.href;
    const newUrl = initUrl || url;
    setInitUrl(newUrl);
    setShowBack(newUrl !== url);
    setCurrentPage(url);
  };

  return (
    <React.Fragment>
      <div style={buttonWrapper}>
        {showBack ? (
          <IconButton>
            <ArrowBackIcon onClick={goBack} />
          </IconButton>
        ) : (
          <span />
        )}
        <IconButton onClick={() => window.open(currentPage, '_blank')}>
          <OpenInNewIcon />
        </IconButton>
      </div>
      <iframe
        ref={iframeRef}
        title="support-iframe"
        style={iframeStyle}
        src={src}
        onLoad={onLoad}
      />
    </React.Fragment>
  );
};

const iframeStyle = {
  height: '90vh',
  border: 'none',
  padding: '0 2px',
};

const buttonWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto 5px',
};

export default IFrame;
