import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const WithToolTip = ({ title, children }) => (
  <>
    {!title ? (
      children
    ) : (
      <Tooltip title={title} placement="left-start">
        <span>{children}</span>
      </Tooltip>
    )}
  </>
);

export default WithToolTip;
