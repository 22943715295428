import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TMDialog from '../lib/Dialog/TMDialog';
import { getReleaseAuditTrail } from '../services/releases';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Moment from 'react-moment';
import exportFromJSON from 'export-from-json';
import useAuth from '../hooks/useAuth';

const moment = require('moment');
const fileName = `src-auditlog-${moment().format('YYYY-MM-DD-hh-mm')}`;

const useStyles = makeStyles((theme) => ({
  separator: {
  	'& td': {
    	borderTop: `solid 3px ${theme.palette.primary.main}`
    }
  },
}));

const AuditTrailDialog = ({ show, onCancel, selectedReleaseAddresses, anonAddresses, connections }) => {
	const classes = useStyles();
	const [auditTrail, setAuditTrail] = useState([]);
	const [csvData, setCsvData] = useState([]);
	const { user } = useAuth();

	const getAuditTrail = async () => {
		if (show && selectedReleaseAddresses.length) {
	    let _auditTrail = await getReleaseAuditTrail({releaseAddresses: selectedReleaseAddresses, anonAddresses, connections});
			_auditTrail.data.forEach(event => {
	    	let description = "";
	    	if (event.description) {
		    	try {
		    		description = event.description;
		    		if (description.alfaPass && description.name) {
		    			description = `${description.name} (Alfapass ${description.alfaPass})`;
		    		}
		    		else {
					if (description.eniNumber) {
		    				description = description.eniNumber;
					} else {
						// description = JSON.stringify(event.description, null, 4);
						description = "";
					}
		    		}
		    	} catch (e) {
		    		description = "";
		    	}
		    }
		    // save it in the event
	    	event.description = description;
	    })
	    setAuditTrail(_auditTrail.data);
	    setCsvData(_auditTrail.data.map(event => ({
	    	blNumber: event.release.container.blNumber,
	    	containerNumber: event.release.container.containerNumber,
			  timestamp: moment(event.operationAt).format('YYYY-MM-DD hh:mm'),
			  event: event.operation,
			  by: event.sourceOrganizationName,
			  user: event.user ? `${event.user.firstName} ${event.user.lastName}` : undefined,
			  to: event.targetOrganizationName,
			  description: event.description,
	    	}))
	    );
	  }
  }

  const closeAuditTrail = () => {
  	//SCRDEV-1003: clear the audit trail before closing the dialog, so it's cleared from the screen when you open another audit trail
  	setAuditTrail([]);
  	onCancel();
  }

	useEffect(() => {
    if (show && (user.features.canAuditTrail)) {
    	getAuditTrail();
    }
  }, [show])

	return (
		<TMDialog
      key="release-auditTrail"
      title="Audit Trail"
      dialogOpen={show}
      handleDialogClose={closeAuditTrail}
      maxWidth={user.features.canAuditTrail ? 'lg' : 'md'}
      showBottomClose={false}
    >
    { user.features.canAuditTrail 
    	? 
    		<React.Fragment>   	
		    	<Paper>
			      <TableContainer style={{maxHeight: '60vh'}}>
				      <Table stickyHeader aria-label="Audit Trail">
				        <TableHead>
				          <TableRow>
				            <TableCell>BL number</TableCell>
				            <TableCell>Container Nbr</TableCell>
				            <TableCell>Timestamp</TableCell>
				            <TableCell>Event</TableCell>
				            <TableCell>By</TableCell>
				            <TableCell>To</TableCell>
				            <TableCell>Description</TableCell>
				          </TableRow>
				        </TableHead>
				        <TableBody>
				          {auditTrail.map((event, index, events) => {
				          	const isNewContainer = index > 0 && event.release.container.containerNumber !== events[index-1].release.container.containerNumber;
				          	return (
					            <TableRow hover key={event.id} className={classNames({[classes.separator]: isNewContainer})}>
					              <TableCell>{event.release.container.blNumber}</TableCell>
					              <TableCell>{event.release.container.containerNumber}</TableCell>
					              <TableCell><Moment format="ddd DD MMM YYYY hh:mm" date={event.operationAt}/></TableCell>
					              <TableCell>{event.operation} </TableCell>
					              <TableCell>
					              	{ event.user 
					              		?
															<Tooltip title={`User: ${event.user.firstName} ${event.user.lastName}`}>
							              		<span style={{cursor: 'pointer', borderBottom: 'dotted 2px #bcbcbc'}}>
						              				{event.sourceOrganizationName}
							              		</span>
							              	</Tooltip>
						              	:
						              		<span>{event.sourceOrganizationName}</span>
						              }
			              		</TableCell>
					              <TableCell>{event.targetOrganizationName}</TableCell>
					              <TableCell><pre>{event.description}</pre></TableCell>
					            </TableRow>
					          )
				          })}
				        </TableBody>
				      </Table>
				    </TableContainer>
			    </Paper>
			    <Button style={{marginTop:'20px'}} variant="contained" color="primary" 
			    				onClick={() => { exportFromJSON({ data: csvData, fileName, exportType: exportFromJSON.types.csv }) }}
					>
			    	Download audit trail as CSV file
		    	</Button>
		    </React.Fragment>
	    :
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '275px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-audit-trail" />
    }

    </TMDialog>
	)
}

export default AuditTrailDialog;
