export const SUBSCR_CLOUD_WALLET = 1;
export const SUBSCR_COMPANY_WALLET = 2;
export const SUBSCR_API = 3;
export const SUBSCR_PREMIUM = 4;
export const SUBSCR_PREMIUM_TRIAL = 5;
export const SUBSCR_TRANSFER_REVOKE = 6;
export const SUBSCR_GREEN_LIGHTS = 7;
export const SUBSCR_NMOT = 8;
export const SUBSCR_ASSIGN = 9;
export const SUBSCR_NONPAYING = 10;
