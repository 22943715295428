import React, { useEffect } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import WithToolTip from './WithTooltip';

export default ({ disabledBtn, options }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    // SCRDEV-279: show unassign when available
    // SCRDEV-405: undo SCRDEV-279
    setSelectedIndex(options.findIndex((o) => !o.disabled));
  }, [options]);
  
  /**
   *
   * @param {Event} event
   * @param {Number} index
   */
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    triggerClick(index);
    event.stopPropagation();
  };

  /**
   *
   * @param {Event} e
   */
  const handleButtonClicked = (e) => {
    triggerClick(selectedIndex);
    e.stopPropagation();
  };

  const triggerClick = (index) => options[index].onClick && options[index].onClick();

  /**
   *
   * @param {Event} e
   */
  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
    e.stopPropagation();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12} /*className={classNames({['showPremiumDot']: !open})}*/>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          disableElevation
          disabled={disabledBtn ? true : false}
        >
          <Button onClick={handleButtonClicked} 
            style={{
              minWidth: '110px'
            }}>
            {options[selectedIndex]?.label}
          </Button>
          <Button
            size="small"
            variant="contained"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 200 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <WithToolTip key={index} title={option.disabled ? option.tooltip : ''}>
                        <MenuItem
                        key={option.label}
                        disabled={option.disabled}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        className={option.className}
                      >
                        {option.label}
                      </MenuItem>
                     </WithToolTip> 
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};