import jwt from 'jsonwebtoken';
import client from '../feathers';
import logSentry from './logSentry'

export const checkExpiredToken = (token) => {
  const value = jwt.decode(token);
  if (!value) return true;

  const { exp } = value;
  return Date.now() >= exp * 1000;
};

export const isTokenValid = () => {
  const item = window.localStorage.getItem('tmining_wallet');
  if (!item) return false;

  try {
    const obj = JSON.parse(item);
    if (!obj.token) return false;

    return !checkExpiredToken(obj.token);
  } catch (e) {
    logSentry(e);
    return false;
  }
};

export const getAccessToken = async () =>
  client.authentication().getAccessToken();

