import { useEffect, useState } from 'react';

const useContainersOptions = (containersByBl, containerFlatView, doGrouping) => {
  const [state, setState] = useState({});

  const makeOptionsState = (containers) => {

    const isAssigned = ({ transferStatus }) => transferStatus === 'assigned';
    const isTransferred = ({ transferStatus }) => transferStatus === 'transferred';
    const isOwned = ({ transferStatus }) => transferStatus === 'owned';
    const isBlocked = ({ releaseStatus }) => releaseStatus === 'blocked';

    return {
      transfer: containers.some(isOwned),
      revoke:   containers.some(isTransferred),
      pincode:  !containers.every(isTransferred),
      assign:   !containers.every(isTransferred),
      unassign: containers.some(isAssigned),
      block:    !containers.every(isBlocked),
      unblock:  containers.some(isBlocked)
    };
  };

  useEffect(() => {
    const calculateContainersOptionState = () => {
      if(doGrouping){
        const arr = Object.entries(containersByBl);
        if (!arr.length) return;

        const optionsState = arr.reduce((acc, [bl, containers]) => {
          acc[bl] = makeOptionsState(containers);

          return acc;
        }, {});
        setState(optionsState);
      }else{
        const optionsForFlatViewContainers = makeOptionsState(containerFlatView)
        setState(optionsForFlatViewContainers);
      }
    };
    calculateContainersOptionState();
  }, [containersByBl, containerFlatView, doGrouping]);

  return state;
};

export default useContainersOptions;
