/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import * as Subscriptions from '../util/subscriptions';
import SubscriptionExpired from '../components/SubscriptionExpired';

const moment = require('moment');

const ProtectedRoute = ({ component: Component, isProtected, ...rest }) => {
  const { accessToken, user } = useAuth();
  const location = useLocation();
  
  // if the user is on its way to the orderform, by all means, let the user go to the orderpage :)
  const isOrderPath = location.pathname === '/order' || location.search.includes('/order')

  // console.log("Check subscriptions", user);
  // console.log("location", location);

  const getActiveSubscription = (subscriptions, requiredSubscriptions) => {
    const now = moment();
    return subscriptions.find(s => 
      requiredSubscriptions.includes(s.subscriptionId) && 
      moment(s.startDate).isSameOrBefore(now, 'day') && 
      (s.endDate === null || moment(s.endDate).isSameOrAfter(now, 'day'))
    )
  }

  if (user) {
    const walletSubscription = getActiveSubscription(user.subscriptions, [Subscriptions.SUBSCR_CLOUD_WALLET, Subscriptions.SUBSCR_COMPANY_WALLET]);

    if (!isOrderPath && !walletSubscription) {
      return <SubscriptionExpired notice="No active ID wallet subscription found."/>
    }

    const flavorSubscription = getActiveSubscription(user.subscriptions, [Subscriptions.SUBSCR_PREMIUM, Subscriptions.SUBSCR_PREMIUM_TRIAL, Subscriptions.SUBSCR_NONPAYING, Subscriptions.SUBSCR_API]);
    
    if (!isOrderPath && !flavorSubscription) {
      return <SubscriptionExpired notice="Your SCR subscription has expired."/>
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {

console.log(window.location);
console.log(isProtected && !accessToken)
        
        return isProtected && !accessToken ? (
          <Redirect to={`/loading${props.location.search ? props.location.search :'?fwd='+props.location.pathname}`} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default withRouter(ProtectedRoute);
