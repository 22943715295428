/* eslint-disable react/jsx-wrap-multilines */
import React, { useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TMPaper from '../lib/Paper/TMPaper';
import useIntl from '../hooks/useIntl';
import useLanguage from '../hooks/useLanguage';
import useAuth from '../hooks/useAuth';
import client from '../feathers';
import CustomSelect from './CustomSelect';
import TMDialog from '../lib/Dialog/TMDialog';

const initialState = {
  preferredLanguage: 'en',
  releaseTransferEmail: false,
  releaseUpdateEmail: true,
  options: { transferred: 'container', updated: 'container' },
  selectOptions: [],
  openPremiumDialog: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_PREFERENCES':
      return { ...state, ...payload };

    default:
      return { ...state, [type]: payload };
  }
};

const Preferences = () => {
  const { translate } = useIntl();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selectOptions: [
      {
        label: translate('settings.preferences.select.byContainer'),
        value: 'container',
      },
      {
        label: translate('settings.preferences.select.byBl'),
        value: 'bl',
        className: 'showPremium'
      },
    ],
  });
  
  const { setLanguage } = useLanguage();
  const history = useHistory();
  const { user } = useAuth();
  
  useEffect(() => {
    handlePreferences();
  }, []);

  const handlePreferences = async () => {
    const preferences = await client.service('preferences').find();
    const { options, ...restPreferences } = preferences;
    if (options) {
      let { transferred, updated  } = options;

      if(!transferred) transferred = 'container';
      if (!updated) updated = 'container';

      restPreferences.options = { transferred, updated }
    }
    dispatch({ type: 'SET_PREFERENCES', payload: restPreferences });
  };

  const handleCheckbox = (e) =>
    dispatch({ type: e.target.name, payload: e.target.checked });
  
  const handleDropdown = (e) =>
    dispatch({ type: e.target.name, payload: e.target.value });

  const handleCustomSelect = (e) => {
    const { name, value } = e.target;
    if (!user.features.canNotifyPerBl && value === 'bl') {
      dispatch({ type: 'openPremiumDialog', payload: true })
    } else {
      dispatch({
        type: 'SET_PREFERENCES',
        payload: { options: { ...state.options, [name]: value } },
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    client.service('preferences').create({ ...state });
    setLanguage(state.preferredLanguage);
    history.push('/');
  };

  return (
    <React.Fragment>
      <TMPaper title={translate('settings.preferences.title')}>
        <form onSubmit={submitHandler}>
          <FormGroup row style={{ alignItems: 'center' }}>
            <Typography component="span" style={{ marginRight: '1em' }}>
              {translate('settings.preferences.preferredLanguage')}
            </Typography>
            <Select
              style={{ minWidth: '120' }}
              name="preferredLanguage"
              labelId="language"
              value={state.preferredLanguage}
              onChange={handleDropdown}
            >
              <MenuItem value="en">{translate('language.en')}</MenuItem>
              <MenuItem value="de">{translate('language.de')}</MenuItem>
              <MenuItem value="fr">{translate('language.fr')}</MenuItem>
              <MenuItem value="it">{translate('language.it')}</MenuItem>
              <MenuItem value="nl">{translate('language.nl')}</MenuItem>
            </Select>
          </FormGroup>
          <FormGroup row style={{ display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="releaseTransferEmail"
                  checked={state.releaseTransferEmail}
                  onChange={handleCheckbox}
                  value={!state.releaseTransferEmail}
                />
              }
              label={translate('settings.preferences.releaseTransferEmail')}
            />
            <FormControlLabel
              control={
                <CustomSelect
                  name="transferred"
                  handleChange={handleCustomSelect}
                  value={state.options.transferred}
                  options={state.selectOptions}
                />
              }
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  name="releaseUpdateEmail"
                  checked={state.releaseUpdateEmail}
                  onChange={handleCheckbox}
                  value={!state.releaseUpdateEmail}
                />
              }
              label={translate('settings.preferences.releaseUpdateEmail')}
            />
            <FormControlLabel
              control={
                <CustomSelect
                  name="updated"
                  handleChange={handleCustomSelect}
                  options={state.selectOptions}
                  value={state.options.updated}
                />
              }
            />
          </FormGroup>

          <Button
            style={{ marginTop: '2em' }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {translate('settings.preferences.savePreferences')}
          </Button>
        </form>
      </TMPaper>

      <TMDialog
        key="premium-transfer-info"
        title={translate('premium.groupedNotifications.title')}
        dialogOpen={state.openPremiumDialog}
        handleDialogClose={() => dispatch({ type: 'openPremiumDialog', payload: false })}
        maxWidth="md"
        showBottomClose={false}
      >
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '350px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-grouped-notifications" />
      </TMDialog>

    </React.Fragment>
  );
};

export default Preferences;
