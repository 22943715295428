import React from 'react';
import TMDataTable from '../../../lib/Table/TMDataTable';
import useIntl from '../../../hooks/useIntl';

const ReleasesDrivers = ({ drivers, onSelect }) => {
  const { translate } = useIntl();

  const columns = ['name', 'phone', 'email', 'alfapass'].map((column) => translate(`driver.${column}`));

  const data = drivers
    .filter(d => d.isActive)
    .map(({ firstName, lastName, phone, email, alfaPass }) => 
      [`${firstName} ${lastName}`, phone, email, alfaPass]
    );

  const options = {
    sort: false,
    print: false,
    filter: false,
    search: true,
    download: false,
    viewColumns: false,
    selectableRows: 'single',
    selectableRowsOnClick: false,
    onSelectionChange: ([selected]) => {
      if (selected) {
        const identification = selected.pop();
        onSelect(identification);
      } else {
        onSelect(undefined)
      }
    },
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
  };

  return (
    <div className="no-radius-table">
      <TMDataTable columns={columns} data={data} options={options} />
    </div>
  )
};

export default ReleasesDrivers;
