import React from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TMPaper from '../lib/Paper/TMPaper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useIntl from '../hooks/useIntl';

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      padding: theme.spacing(3),
      margin: '0 auto',
      width: '60%'
    },
    expired: {
      marginTop: '40px',
      '& th': {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        textAlign: 'left',
        padding: '10px'
      },
      '& td': {
        textAlign: 'left',
        padding: '10px',
        verticalAlign: 'top'
      }
    }
  }));

const SubscriptionExpired = ({notice}) => {

  const { translate } = useIntl();
  const classes = useStyles();

  const handleLogout = () => {
    window.addToDataLayer('subscription-expired', 'logout');
    localStorage.clear();
    sessionStorage.clear()
    window.location.reload();
  }
  
  return (
    <div className={classes.content}>
      <TMPaper
        key="dialog"
        title={notice}
      >
        <p>Therefore, your SCR account has been deactivated temporarily. 
           <br/>As communicated before, you have 2 options:
        </p>

        <table className={classes.expired}>
          <thead>
            <tr>
              <th>Option 1</th>
              <th>Option 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Continue using <b>SCR Premium</b></td>
              <td>Work for free with <b>SCR Basic</b></td>
            </tr>
            <tr>
              <td>
                <p>Continue using all the SCR Premium productivity features while your ID wallet is hosted in the cloud for you. Nothing will change!</p>
                <p style={{marginTop: '20px'}}>
                  <NavLink to="order">
                    <Button variant="contained" color="primary">{ translate('navbar.order') }</Button>
                  </NavLink>
                </p>
              </td>
              <td>
                <p>To migrate to SCR Basic, you need to install the ID wallet on premise, on your infrastructure or in your cloud environment. </p>
                <p style={{marginTop: '20px'}}>
                  <a href="https://www.securecontainerrelease.com/s/SCR-How-to-switch-to-company-wallet" target="_new">Click here for the guide to help you with the installation.</a>
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="2" style={{align: 'center'}}>
                For more information, visit <a href="https://www.securecontainerrelease.com/scr-premium" target="_ new">https://www.securecontainerrelease.com/scr-premium</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <Button variant="outlined" color="secondary" onClick={handleLogout} style={{float: 'right', marginRight: '40px'}}>
            { translate('navbar.logout') }
          </Button>
        </div>
      </TMPaper>
    </div>
  )
}

export default (SubscriptionExpired);