import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';

import WithToolTip from './WithTooltip';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function CustomSelect({ handleChange, options, value, name }) {
  return (
    <div>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {options.map((option) => {
          return !option.disabled ? (
            <MenuItem key={option.value} value={option.value} className={option.className}>{option.label}</MenuItem>
          ) : (
            <WithToolTip
              key={option.value}
              title={option.tooltip}
            >
              <MenuItem key={option.value} value={option.value} className={option.className} disabled>{option.label}</MenuItem>
            </WithToolTip>
          );
        })}
      </Select>
    </div>
  );
}

CustomSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};
