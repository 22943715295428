import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TMDateInput from '../lib/Input/TMDateInput';
import OrganizationSelectionTable from './OrganizationSelectionTable'

const classes = theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    display: 'flex', 
    alignItems: 'top', 
    marginBottom: '1em'
  },
  label: {
    display: 'flex',
    marginTop: '1em',
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
  },
  secondaryLabel: {
    display: 'flex',
    marginTop: '0.7em',
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: 'bold'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '1em'
  },
  radio: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  dateField: {
    width: `${theme.spacing(1.4)}em`, // '11em'
  },
  timeField: {
    width: `${theme.spacing(1.1)}em`, // '7em'
  },
  hidden: {
    display: 'none'
  },
  button: {
    marginRight: theme.spacing(2)
  },
  tooltip: {
    cursor: 'pointer'
  },
  tooltext: {
    fontSize: '1.8em',
    lineHeight: '1.5em'
  },
  readOnly: {
    padding: theme.spacing(2),
    '& input, div[class*="multiline"], div[class*="TMAutocomplete"]': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  link: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.light,
    padding: '0.75em',
  },
  smallText: {
    color: theme.palette.secondary.dark,
    display: 'block'
  },
  primaryText: {
    color: theme.palette.primary.main,
  }
});

class SRPForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubmitDisabled: false,
      showBackdrop: false,
    }
  };

  hideBackdrop = () => {
    this.setState({ showBackdrop: false })
  };

  submitForm = async () => {
    if ([undefined, false].includes(this.props.needsConfirm) || window.confirm(this.props.confirmMsg)) {
      this.setState({
        isSubmitDisabled: true,
        showBackdrop: true,
      }, () => {
        this.props.handleSubmit()
        .then(() => {
          this.setState({ 
            isSubmitDisabled: false,
            showBackdrop: false
          })
        })
      })
    }
  };

  render() {
    
    let { classes, object, fields, readOnly=false, variant='outlined',
          handleChange, handleCancel, handleSkip, submitLabel, 
          cancelLabel, skipLabel, children, enableSubmitCondition=true } = this.props;

    let { isSubmitDisabled, showBackdrop } = this.state;

    // sometimes fields are skipped, so filter them out here first
    let nonEmptyFields = fields.filter(item => item.hasOwnProperty('field'));

    return (
      <ValidatorForm
        id={this.props.id}
        ref="form" 
        className={classNames({[classes.readOnly]: readOnly})}
        onSubmit={this.submitForm}
        instantValidate={false}
        onError={errors => console.log(errors.map(err => err.getErrorMessage()))}
      >
        <Grid container spacing={2} className={classes.formRow}>
        {
          nonEmptyFields.map((item, idx) => {
            let validators = item.validators === undefined ? [] : item.validators;
            let errorMessages = item.errorMessages === undefined ? [] : item.errorMessages;
            
            if (item.required) {
              validators.push('required');
              let article = (/^[aeiouy]/).test(item.label.toLowerCase()) ? 'an' : 'a';
              errorMessages.push(`Please provide ${article} ${item.label.toLowerCase()}`);
            }
            
            if (item.isEmail) {
              validators.push('isEmail');
              errorMessages.push('Email is not valid');
              item.type = 'email';
            }

            if (!item.hasOwnProperty('type')) {
              item.type = 'text';
            }

            let inputProps = {};
            if (item.prefix !== undefined) {
              inputProps.startAdornment = (<InputAdornment position="start">{item.prefix}</InputAdornment>);
            }
            if (item.suffix !== undefined) {
              inputProps.endAdornment = (<InputAdornment position="end">{item.suffix}</InputAdornment>);
            }

            let attributes = { 
              name: item.field,
              id: item.field,
              value: object[item.field], 
              type: item.type,
              placeholder: item.placeholder ? item.placeholder : item.label, 
              rows: item.rows,
              multiline: item.multiline,
              // autoFocus: idx === 0, // makes a annoying jump in the order scr premium form
              onChange: handleChange, 
              onBlur: item.onBlur, 
              onClick: item.onClick, 
              margin: 'none',
              variant: variant, 
              readOnly: readOnly, 
              validators: validators,
              suggestions: item.suggestions ? item.suggestions : [],
              disabled: item.disabled,
              color: 'primary',
              helperText: item.helperText, 
              fullWidth: true,
              errorMessages: errorMessages,
              InputProps: inputProps,
              required: !!item.required || false
            }

            let children = null;
            let ComponentType = item.componentType;

            // override 1
            if (ComponentType && ComponentType === Switch) {
              // too many attributes cause errors, so be selective here
              attributes = { 
                name: item.field,
                value: [undefined, null].includes(object[item.field]) ? "" : object[item.field].toString(), 
                checked: !!object[item.field],
                onChange: handleChange, 
                color: 'primary',
                disabled: item.disabled,
              }
            }
            
            // override 2
            if (ComponentType && ComponentType === Select) {
              delete attributes.helperText
              delete attributes.errorMessages
              delete attributes.InputProps
              attributes.labelId = attributes.id
              attributes.displayEmpty = true
              children = item.suggestions.map(s => <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>)
              children.unshift(<MenuItem key='empty' disabled>{attributes.placeholder}</MenuItem>)
            }

            // override 3
            if (ComponentType && ComponentType === TMDateInput) {
              delete attributes.errorMessages
            }

            // override 4
            if (ComponentType && ComponentType === Button) {
              attributes = {
                variant: item.variant,
                href: item.href,
                color: 'primary',
                disableElevation: true,
              }
              children = item.btnLabel ? item.btnLabel : item.href
            }

            // override 5
            if (ComponentType === OrganizationSelectionTable) {
              attributes = {
                organizations: item.suggestions,
                onSelect: item.handleChange
              }
            }

            // override 6
            if (item.type === 'file') {
              attributes.accept = item.accept;
            }

            // override 7
            if (item.field === '_divider') {
              ComponentType = Divider;
              item.fullWidth = true;
              attributes = {
                className: classes.divider
              };
            }

            return (
              <React.Fragment key={idx}>
                <Grid item xs={12} sm={2} className={item.field === '_divider' ? classes.secondaryLabel : classes.label}>
                  {item.label} {item.required && '*'}
                </Grid>
                <Grid item xs={12} sm={item.fullWidth === true || nonEmptyFields.length === 1 ? 10 : 4}>
                  {
                    // overwrite for Checkbox
                    (ComponentType && [Switch, Checkbox].includes(ComponentType)) &&
                      <FormGroup row>
                        <FormControlLabel
                          control={ <ComponentType color="primary" checked={!!object[item.field]} value={(object[item.field]).toString()} onChange={handleChange} name={item.field} /> }
                          label={item.placeholder || item.helperText}
                          style={{fontSize: '0.9em'}}
                        />
                      </FormGroup>
                  }
                  {
                    (ComponentType && ComponentType === Typography) &&
                      <React.Fragment>
                        <Typography variant={item.variant} id={item.field} className={classes.link} display="block">
                          { object[item.field] }
                        </Typography>
                        <small className={classes.smallText}>{item.helperText}</small>
                      </React.Fragment>
                  }
                  {
                    (!ComponentType || ![Switch, Checkbox, Typography].includes(ComponentType)) &&
                      React.createElement(
                        ComponentType ? ComponentType : TextValidator, 
                        attributes,
                        children
                      )
                  }
                  {
                    (ComponentType && ([Button, Divider, Switch, OrganizationSelectionTable].includes(ComponentType))) &&
                      <small className={classNames(classes.smallText, {[classes.primaryText]: ComponentType === Divider})} style={{marginTop: '5px'}}>{item.helperText}</small>
                  }
                </Grid>
              </React.Fragment>
            )
          })
        }
        </Grid>
        <Grid container spacing={2}>{children}</Grid>
        <Grid container spacing={2}>
          <Grid item xs={2} className={classes.label}></Grid>
          <Grid item xs={10}>
            {
              submitLabel && <Button type="submit" className={classes.button} variant="contained" size="large" disabled={isSubmitDisabled || !enableSubmitCondition} color="primary">{submitLabel}</Button>
            }
            {
              skipLabel && <Button onClick={handleSkip} className={classes.button} variant="outlined" size="large" color="primary">{skipLabel}</Button>
            }
            {
              cancelLabel && <Button onClick={handleCancel} className={classes.button} variant="outlined" size="large" color="secondary">{cancelLabel}</Button>
            }
          </Grid>
        </Grid>

        <Backdrop 
          open={showBackdrop} 
          // otherwise the backdrop is stuck in the bottom part of the screen ('88vh' defined in App.js)
          style={{position: 'absolute', zIndex: showBackdrop ? 10000 : -1}} 
          onClick={this.hideBackdrop}
        />

      </ValidatorForm>
    );
  }
}

SRPForm.propTypes = {
  classes: PropTypes.object.isRequired,
  // object: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
};

export default withStyles(classes)(SRPForm);