import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { fetchTermsAndConditions } from '../util/terms-and-conditions';

const TermsAndConditions = ({ blNumber, anonAddresses }) => {
  const [html, setHtml] = useState('');

  const getTermsHtml = useCallback(async () => {
    const { data } = await fetchTermsAndConditions(blNumber, anonAddresses);
    setHtml(data);
  }, []);

  useEffect(() => {
    getTermsHtml();
  }, [html, getTermsHtml]);

  return (
    <div style={{ padding: '0 30px' }}>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

TermsAndConditions.propTypes = {
  blNumber: PropTypes.string.isRequired,
};

export default TermsAndConditions;
