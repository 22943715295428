export default {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',

  SET_DRIVERS: 'SET_DRIVERS',
  SET_BARGES: 'SET_BARGES',
  
  SET_IDENTIFICATION: 'SET_IDENTIFICATION',

  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB'
};
