import React, { useEffect } from 'react';
import client from '../../feathers';
import classNames from 'classnames';
import TMDialog from '../../lib/Dialog/TMDialog';
import useIntl from '../../hooks/useIntl';

import './NMoT.css';

const options = [
  { path: 'nmot-truck.jpg', caption: 'Truck', value: 'truck' },
  { path: 'nmot-barge.jpg', caption: 'Barge', value: 'barge' },
  { path: 'nmot-rail.jpg', caption: 'Rail', value: 'rail' },
  { path: 'nmot-unknown.jpg', caption: "I don't know", value: 'unknown' },
];

const NMoTDialog = (
  ({
    show,
    selected,
    onSelection,
    onCancel,
    selectedReleaseAddresses,
    classes,
  }) => {

    const { translate } = useIntl();

    const handleNmot = async () => {
      if (selected) {
        await client.service('nmots').create({
          releases: selectedReleaseAddresses,
          modeOfTransport: selected,
        });
        onCancel();
      }
    };

    useEffect(() => {
      handleNmot();
    }, [selected]);

    return (
      <TMDialog
        key="nmot-dialog"
        title="Please select Next Mode Of Transport"
        dialogOpen={show}
        handleDialogClose={onCancel}
        maxWidth="sm"
        showBottomClose={false}
        // disableBackdropClick={true}
        // disableEscapeKeyDown={true}
        showTopClose={false}
        zIndex={10000}
      >
        <p className="summary">
          {translate('nmot.select')}
        </p>
        <div className="icons-wrapper">
          {options.map(({Icon, path, value, caption}) => (
            <div key={value} 
                 className={classNames('icon-container', {['selected']: selected === value})} 
                 onClick={() => onSelection(value)}>
              <img src={`/assets/${path}`} alt={caption}/>
              <span className="caption">{caption}</span>
            </div>
          ))}
        </div>
      </TMDialog>
    );
  }
);

export default NMoTDialog;
