import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react'
import { mdiChatQuestion as RequestForReleaseIcon } from '@mdi/js';
import useIntl from '../../hooks/useIntl';
import RequestForReleaseDialog from './RequestForReleaseDialog';

const RequestForReleaseButton = ({user, fetchRequestsForRelease}) => {
  
  const [open, setOpen] = useState(false);
  const { translate } = useIntl();

  return (
    <React.Fragment>
      <Tooltip title={translate('req.for.release.tooltip')}>
        <IconButton onClick={() => { window.addToDataLayer('request-for-release', 'open'); setOpen(true)}} 
                    id="RequestForReleaseButton" aria-label="Request For Release">
          <Icon path={RequestForReleaseIcon} size={1}/>
        </IconButton>
      </Tooltip>

      <RequestForReleaseDialog
        user={user}
        fetchRequestsForRelease={fetchRequestsForRelease}
        open={open}
        handleDialogClose={(cb) => {
          setOpen(false);
          cb && cb();
        }}
      />
    </React.Fragment>
  );
};

export default RequestForReleaseButton;
