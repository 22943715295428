import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import More from '@material-ui/icons/More';
import MenuItem from '@material-ui/core/MenuItem';
import TMMenu from './TMMenu.jsx';

class TMMobileIconMenu extends React.Component {

	state = {
    anchorEl: null,
  };

	/* sets the anchor element to the profile menu iconbutton */
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  /* clears the anchor element, making the menu disappear */
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {

  	const { classes, isAuthenticated, navLinks } = this.props;
  	const { anchorEl } = this.state;

  	return (
  		<React.Fragment>
	  		{ isAuthenticated && 
        		<ClickAwayListener onClickAway={this.handleMenuClose}>
					  	<div>
						  	<IconButton 
						      aria-owns='mobile-menu' 
						  		onClick={this.handleMenuOpen}
						      className={classes.buttonIcon}
						    >
	                <More />
	              </IconButton>
	              <TMMenu
	                id='mobile-menu'
	                anchorEl={anchorEl}
	                handleMenuClose={this.handleMenuClose}
	              >
	                {
	                  navLinks.map((link, index) => {
	                    if (link.needsAuthentication === false || (link.needsAuthentication && isAuthenticated)) {
	                      return <MenuItem key={index}><NavLink className={classes.menuItem} activeClassName={classes.active} to={link.path}>{link.label}</NavLink></MenuItem> 
	                    } else {
	                    	return <span key={index}/>
	                    }
	                  })
	                }
	              </TMMenu>
	            </div>
        		</ClickAwayListener>
		  	}
		 	</React.Fragment>
		);
	}
}

TMMobileIconMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.object),
};

export default (TMMobileIconMenu);

