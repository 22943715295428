import React from 'react';
import useErrorDrawer from './useErrorDrawer';
import useIntl from './useIntl';

import { checkExpiredToken } from '../util/token';

import useSentry from '../hooks/useSentry';

const useWalletError = () => {
  const onError = useErrorDrawer();
  const { translate } = useIntl();
  const logSentry = useSentry();

  const onWalletError = (e) => {
    // Log exception to sentry
    logSentry(e);

    const walletInfo = window.walletApi._getWalletInfo();

    let eMessage = e.message;
    let status = 500;

    if (e.response) {
      eMessage = e.response.statusText;
      status = e.response.status;
    }
    
    if (status === 401 && ([undefined, '', 'Unauthorized'].includes(eMessage)) &&
        walletInfo && walletInfo.token && checkExpiredToken(walletInfo.token)) 
    {
      eMessage = translate('session.expired');
    }

    const message = (
      <div>
        {status === 401
          ? translate('walletAuthentication.message')
          : translate('walletError.message')}

        {e && (
          <div
            style={{
              fontFamily: 'monospace',
              marginTop: 10,
              padding: 10,
              backgroundColor: '#eee',
            }}
          >
            {`${status}: ${eMessage}`}
          </div>
        )}
      </div>
    );

    onError(status, message, translate('walletError.title'), 'wallet.html');
  };

  return onWalletError;
};

export default useWalletError;
