import { removeSpecialCharacters } from '../util/release';

const useLocalFilter = (doGrouping) => {
  const makeFilterExpression = (data, filter) => {

    return Object.entries(filter).reduce((result, [key, val]) => {
      if (Array.isArray(val)) {
        return val.length ? result && val.includes(data[key]) : result;
      }

      return val
        ? result && data[key] && val &&
            removeSpecialCharacters(data[key].toLowerCase()) ===
              removeSpecialCharacters(val.toLowerCase())
        : result;
    }, true);
  };

  const applyFilter = (data, filter) => {
    if (!Object.keys(filter).length) return data;

    const {
      blNumber,
      vessel,
      voyageNumber,
      portOfLoading,
      portOfDestination,
      containerNumber,
      pickupLocation,
      turnInLocation,
      transferStatus,
      releaseStatus,
    } = filter;
    const bolFilter = {
      blNumber,
      vessel,
      voyageNumber,
      portOfLoading,
      portOfDestination,
    };

    const containerFilter = {
      containerNumber,
      pickupLocation,
      turnInLocation,
      transferStatus,
      releaseStatus,
    };

    let filteredCriteria = data.filter((bill) =>
      makeFilterExpression(bill, bolFilter)
    );
    let flatViewFilter;
    if(!doGrouping){
      flatViewFilter = filteredCriteria.filter((container) =>
            makeFilterExpression(container, containerFilter)).filter((_) => _);
    }

    const filteredData = filteredCriteria
      .map((bill) => {
        let containers = bill.containers?.filter((container) =>
          makeFilterExpression(container, containerFilter)
        );
        if (!containers?.length) return;

        return { ...bill, containers };
      })
      .filter((_) => _);
    return doGrouping ? filteredData : flatViewFilter;
  };
 
  return applyFilter;
};

export default useLocalFilter;
