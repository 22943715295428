import React, { createContext, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

const ProgressBarContext = createContext();

const ProgressBarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const show = (flag = true) => setOpen(flag);

  return (
    <ProgressBarContext.Provider value={show}>
      {open && (
        <div
          style={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            opacity: '.5',
            backgroundColor: 'black',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 10000,
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      {children}
    </ProgressBarContext.Provider>
  );
};

export { ProgressBarProvider, ProgressBarContext };
