import useDrawer from './useDrawer';
import useDialog from './useDialog';
import useIntl from './useIntl';
import { checkExpiredToken } from '../util/token';

const useErrorDrawer = () => {
  const { openDrawer } = useDrawer();
  const { setMessage, setTitle, setStatus } = useDialog();
  const { translate } = useIntl();

  const onError = (status, message, title, helpPage) => {
    const feathersToken = sessionStorage.getItem('jwt-accessToken');

    if (status === 401 && ([undefined, '', 'Unauthorized'].includes(message)) &&
        feathersToken && checkExpiredToken(feathersToken)) 
    {
      message = translate('session.expired');
    }

    setStatus(status);
    message && setMessage(message);
    title && setTitle(title);
    status !== 401 && helpPage && openDrawer(helpPage);
  };

  return onError;
};

export default useErrorDrawer;
