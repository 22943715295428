import React, { createContext, useState } from 'react';
import TMDialog from '../lib/Dialog/TMDialog';

const DialogContext = createContext();

const DialogProvider = ({ children }) => {
  const [message, setMessage] = useState();
  const [title, setTitle] = useState();
  const [status, setStatus] = useState();
  const handleDialogClose = () => {
    setMessage(null);
    if (status === 401) window.location.reload();
  };

  return (
    <DialogContext.Provider value={{ setMessage, setTitle, setStatus }}>
      {children}
      <TMDialog
        title={title}
        dialogOpen={!!message}
        handleDialogClose={handleDialogClose}
      >
        <div>{message}</div>
      </TMDialog>
    </DialogContext.Provider>
  );
};

export { DialogContext, DialogProvider };
