import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const textStyles={
  height:'40px', textTransform:'none', fontSize:'0.94rem', fontWeight:'600'
}
const tabStyles={
  height:'45px', minHeight:'42px'
}
export default ({ options }) =>  {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <Paper style={{boxShadow:'none', backgroundColor: '#ddd'}} square>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="disabled tabs example"
        style={tabStyles}
      >
        {options.map((option, index) => (
                <Tab
                    label={option.label}
                    disabled ={option.disabled}
                    onClick={() => option.handleSelectView()}
                    style={textStyles}
                    className={option.className}
                />
        ))}
      </Tabs>
    </Paper>
  );
}