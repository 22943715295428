import React from 'react'
import useIntl from '../../hooks/useIntl'

const BatchUploadReport = ({ data }) => {
	console.log("data", data);
	const { translate } = useIntl();
	const validationErrors = data.validationResults.filter(res => res.errorText);
	const createSuccesses = data.createResults
		.filter(res => res.statusText)
		.map(res => res.data.map(r => ({...r, statusText: res.statusText}) ))
		.flat();
	const createErrors = data.createResults
		.filter(res => res.errorText)
		.map(res => res.data.map(r => ({...r, errorText: res.errorText}) ))
		.flat();
	return (
		<div>
			<p>{translate('fileUpload.report.title')}</p>
			<p>{translate('fileUpload.report.success')}</p>
			<ul>
			{ 
				createSuccesses.map((result, i) => {
					let transferResult = "";
					if (data.transferResults) {
						transferResult = data.transferResults.eligible_releases.find(r => r.address === result.address)
						if (transferResult === undefined) {
							transferResult = data.transferResults.errors.find(r => r.address === result.address)
							if (transferResult) {
								transferResult = transferResult.error
							}
						} else {
							transferResult = translate('carrierui.multi.transfer.ok')
						}
					}
					return (
						<li key={i}>
							BL {result.blNumber}, CTR {result.containerNumber}:
							&nbsp; 
							{translate('carrierui.multi.create.ok')} { transferResult }
						</li>
					)
				})
			}
			</ul>
			<ul>
			{
				createErrors.concat(validationErrors).map((result, i) => {
					return (
						<React.Fragment>
							{ i === 0 && <p>{translate('fileUpload.report.error')}</p> }
							<li key={i}>
								BL {result.billOfLading.blNumber}, CTR {result.container.containerNumber}:
								&nbsp; 
								{result.errorText}
							</li>
						</React.Fragment>
					)
				})
			}
			</ul>
		</div>
	)
}

export default BatchUploadReport
