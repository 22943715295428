import React, { useState } from 'react';
import TMDataTable from '../lib/Table/TMDataTable';
import useIntl from '../hooks/useIntl';

const OrganizationSelectionTable = ({ organizations, onSelect, isMultiSelect, columns=['company', 'address'], data }) => {
  const { translate } = useIntl();
  const [rowsSelected, setRowsSelected] = useState([]);

  const columnLabels = columns.map((l) => translate(`org.select.${l}`));
  data = data ? data : organizations.map(({ name, address, locationAddress }) => [
    name,
    locationAddress,
    address,
  ]);
  const options = {
    pagination: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    sort: true,
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    selectableRows: isMultiSelect ? 'multiple' : 'single',
    search: true,
    selectableRowsOnClick: true,
    rowsSelected: rowsSelected, // User provided array of numbers (dataIndexes) which indicates the selected rows
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setRowsSelected(rowsSelected);
      const selectedOrganization = organizations[rowsSelected[0]];
      onSelect && rowsSelected.length
        ? onSelect(selectedOrganization)
        : onSelect(null);
    },
    textLabels: {
      body: {
        noMatch: translate('org.select.noConnectedOrganizations'),
      },
    },
  };

  return <TMDataTable columns={columnLabels} data={data} options={options} />;
};

export default OrganizationSelectionTable;
