/* eslint-disable import/no-named-as-default */
import ReleasesOverview from './ReleasesOverview';
import RequestForReleaseOverview from './RequestForReleaseOverview';
import OrderPremium from './OrderPremium';
import BargesOverview from './BargesOverview';
import DriversOverview from './DriversOverview';
import CreateTicket from './CreateTicket';
import Settings from './Settings';
import Loader from '../components/Loading';
import superagent from 'superagent';

export const ROUTES = [
  { 
    path: 'login', 
    isProtected: false,
    onClick: () => { console.log(window.location) },
  },
  { 
    path: 'logout', 
    isProtected: false,
    onClick: () => { window.location.replace("/oauth/auth0/logout") },
  },
  { 
    path: 'order', 
    component: OrderPremium,
    helpPage: 'order.html',
    title: 'navbar.order',
    category: 'profile',
    isProtected: true,
    onClick: () => { window.addToDataLayer('profilemenu','order') },
  },
  { 
    path: 'releases', 
    component: ReleasesOverview,
    helpPage: 'releasepage.html',
    title: 'navbar.overview',
    category: 'profile',
    isProtected: true,
    onClick: () => { window.addToDataLayer('profilemenu','releases') },
  },
  { 
    path: 'requests-for-release', 
    component: RequestForReleaseOverview,
    isProtected: true,
    title: 'req.for.release.navbar',
    category: 'profile',
    requiresFeature: 'canRequestForRelease',
    // helpPage: 'request-for-releasepage.html',
    onClick: () => { window.addToDataLayer('profilemenu','request-for-release') },
  },
  { 
    path: 'barges', 
    component: BargesOverview, 
    isProtected: true, 
    title: 'navbar.barges',
    category: 'profile',
    requiresFeature: 'canAssignBarge',
    onClick: () => { window.addToDataLayer('profilemenu', 'barges') },
  },
  { 
    path: 'drivers', 
    component: DriversOverview, 
    isProtected: true, 
    title: 'navbar.drivers',
    category: 'profile',
    requiresFeature: 'canAssignDriver',
    onClick: () => { window.addToDataLayer('profilemenu', 'drivers') },
  },
  {
    path: 'settings',
    component: Settings,
    helpPage: 'settings.html',
    title: 'navbar.settings',
    category: 'profile',
    isProtected: true,
    onClick: () => { window.addToDataLayer('profilemenu', 'settings') },
  },
  { path: 'loading', component: Loader },
  {
    path: '',
    component: ReleasesOverview,
    helpPage: 'releasepage.html',
    title: 'navbar.overview',
    // category: 'top',
    isProtected: true,
  },
  {
    path: '/',
    adminOnly: true,
    title: 'navbar.govApp',
    category: 'profile',
    isProtected: true,
    onClick: () => { window.addToDataLayer('profilemenu', 'govapp'); window.open(process.env.REACT_APP_GOV_APP_LINK) },
  },
  {
    path: '/',
    title: 'navbar.logout',
    category: 'profile',
    onClick: async () => {
      window.addToDataLayer('profilemenu', 'logout');

console.log(window.localStorage.getItem("tmining_wallet"));
console.log(window.localStorage.getItem("tmining_wallet").token);


let {token} = window.walletApi._getWalletInfo();


console.log(window.resetAuthentication);


   try {
let result = await superagent.post(process.env.REACT_APP_OAUTH_REVOKE_ENDPOINT)
		.send({token, client_id: "webapp-SCR"})
console.log(result);
    } catch (error) {
     console.log(error);
    } finally {
      localStorage.clear();
      sessionStorage.clear()

//    props.resetAuthentication();
window.logoutApp();
    }

//      window.location.reload();
    },
  },
  { 
    path: 'ticket', 
    component: CreateTicket, 
    isProtected: true,
    onClick: () => { window.addToDataLayer('profilemenu','ticket') },

  },

];
