import React, { useState, useReducer, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import SRPForm from '../components/SRPForm';
import client from '../feathers';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import useIntl from '../hooks/useIntl';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { useAlertDialog } from '../context/AlertDialogProvider';
import useSentry from '../hooks/useSentry';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    color: '#757575',
    '&:hover': {
    	color: theme.palette.primary.main
    }
  },
  inactive: {
    '& td': {
      color: theme.palette.secondary.main,
    }
  },
}));

export default function BargesOverview() {
	
	const [openAdd, setOpenAdd] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const { enqueueSnackbar } = useSnackbar();
	const { translate } = useIntl();
	const classes = useStyles();
	const logSentry = useSentry();

	const fetchBarges = async () => {
		const result = await client.service('barges').find();
	  dispatch({ payload: { barges: result.data } })
  };

  useEffect(() => {
    fetchBarges();
  }, []);

	const columns = [
		'Name',
		'ENI number',
		'Action',
		{
      name: "isActive",
      options: { filter: false, download: false, display: false, viewColumns: false }
    }, 
	];

	const openEditBarge = async (barge) => {
    dispatch({ payload: {
    	name: barge.name,
		  identification: barge.identification,
		  isActive: barge.isActive,
			organizationAddress: barge.organization_address,
			id: barge.id,
    }})
    setOpenEdit(true);
  };

	const handleChange = (e) => {
    dispatch({ payload: { 
    	[e.target.name]: (e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value) 
    }})
  };

  const handleAddBarge = async () => {
    let didNotError = true;
    try {
    	showProgressDialog();
    	// validation
      const { isNew } = await client.service('barges').create(state);
      enqueueSnackbar(translate(isNew ? "barge.created" : "barge.alreadyExists"), { autoHideDuration: 1500 });
    } catch (error) {
      logSentry(error);
    	showAlert(error.message);
    	didNotError = false;
    } finally {
      dispatch({ type: 'RESET' });
      didNotError && await fetchBarges();
      setOpenAdd(false);
      hideProgressDialog();
    }
	}

	const handleEditBarge = async () => {
    let didNotError = true;
    try {
    	showProgressDialog();
			const data = {
				name: state.name,
				eniNumber: state.identification,
				isActive: state.isActive
			}
      await client.service('barges').patch(state.id, data);
      enqueueSnackbar("Barge updated succesfully", { autoHideDuration: 1500 });
    } catch (error) {
      logSentry(error);
    	showAlert(error.message);
    	didNotError = false;
    } finally {
      dispatch({ type: 'RESET' });
      didNotError && await fetchBarges();
      setOpenEdit(false);
      hideProgressDialog();
    }
	}

	const setRowProps = (row, dataIndex) => {
    // last field = isActive
    if(row[row.length-1] === false) return { className: classes.inactive }
  };

	const getData = () => {
		return state.barges.map(b => [
			b.name, 
			b.identification, 
			<IconButton className={classes.hoverButton} onClick={() => openEditBarge(b)}><EditIcon/></IconButton>,
			b.isActive
		])
	}

	return (
		<React.Fragment>
			<div className="barges-table">
				<TMDataTable
		      title={'Barges'}
		      columns={columns}
		      data={getData()}
		      setRowProps={setRowProps}
		      options={{
			      print: false,
			      download: false,
			      customToolbar: () => 
			      	<IconButton className={classes.hoverButton} onClick={() => setOpenAdd(true)}><CreateIcon/></IconButton>
		      }}
		    />
	    </div>

	    <TMDialog
	      key="add-barge"
	      title={"Add barge"}
	      dialogOpen={openAdd}
	      handleDialogClose={() => setOpenAdd(false)}
	      maxWidth="md"
	      showBottomClose={false}
	    >
	      <SRPForm
	        object={state}
	        handleChange={handleChange}
	        handleSubmit={handleAddBarge}
	        submitLabel={"Add barge"}
	        handleCancel={() => setOpenAdd(false)}
	        cancelLabel={translate("general.cancel")}
	        fields={[
	    			{ field: 'name', label: 'Name', required: true }, 
	    			{ field: 'identification', label: 'ENI number', required: true }, 
	        ]}
	      />
	    </TMDialog>

	    <TMDialog
	      key="edit-barge"
	      title={"Edit barge"}
	      dialogOpen={openEdit}
	      handleDialogClose={() => setOpenEdit(false)}
	      maxWidth="md"
	      showBottomClose={false}
	    >
	      <SRPForm
	        object={state}
	        handleChange={handleChange}
	        handleSubmit={handleEditBarge}
	        submitLabel={translate("barge.edit")}
	        handleCancel={() => setOpenEdit(false)}
	        cancelLabel={translate("general.cancel")}
	        fields={[
	    			{ field: 'name', label: 'Name', required: true }, 
	    			{ field: 'identification', label: 'ENI number', required: true }, 
            { field: 'isActive', label: 'Active ?', componentType: Switch },
	        ]}
	      />
	    </TMDialog>
		</React.Fragment>
	);
}

const initialState = {
  barges: [],
  name: '',
  identification: '',
  isActive: false,
  organizationAddress: '',
  id: 0,
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState }
    default:
      // = SET
      return { ...state, ...payload }
  }
}
