// import React from 'react';
import { getFlatExcelWorkbook } from '../util/excel';
import { saveAs } from 'file-saver';
import getPincode from '../util/pincode';
import useAuth from './useAuth';
import useIntl from './useIntl';
import useWalletError from '../hooks/useWalletError';
// import useSentry from '../hooks/useSentry';

const useFlatExcel = () => {
  const { user } = useAuth();
  const { translate } = useIntl();
  const onWalletError = useWalletError();
  // const logSentry = useSentry();

  /**
   * Download given excel workbook
   * @param {excel} workbook
   */
  const download = async (workbook) => {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'Releases.xlsx');
  };

  /**
   * flatten bill numbers
   * @param {Array[objects]} bill
   */

  const formatReleases = (bill) => {
    return bill.reduce(
      (result, { releases, ...bill }) =>
        result.concat(
          releases.map((release) => ({
            ...bill,
            ...release,
            releaseStatus: translate(`releaseStatus.${release.releaseStatus}`),
          }))
        ),
      []
    );
  };

  /**
   * Fetch pincodes and add pincode to each release
   * @param {Array of releases} releases
   */

  const addPincodeToReleases = async (releases) => {
    let results = await getPincode(releases.map(({address, version}) => ({address, version})), user.organization);
    return releases.map(release => { 
      let result = results.find(res => res.address === release.address)
      release.pincode = result ? (result.pincode ? result.pincode : result.error) : undefined;
      return release;
    })
  };

  /**
   * It downloads releases in flat table format
   * @param {Array} data - data to be downloaded
   * @param {Boolean} fetchPincode
   */
  const flatExcel = async (data, fetchPincode) => {
    let releases = formatReleases(data);
    if (fetchPincode) {
      try {
        releases = await addPincodeToReleases(releases);
      } catch (e) {
        onWalletError(translate(e.message));
      }
    }

    await download(getFlatExcelWorkbook(releases, fetchPincode));
  };

  return flatExcel;
};

export default useFlatExcel;
