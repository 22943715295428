/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useReducer, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import {
  Paper,
  TextField,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useIntl from '../hooks/useIntl';
import useProgressBar from '../hooks/useProgressBar';
import { useSnackbar } from 'notistack';

import { Http } from '../axios';

const margin = '0 2rem';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      padding: 30,
      width: '80%',
    },
    createButton: {
      marginTop: '.75rem',
      alignSelf: 'right',
      margin,
    },
    container: {
      display: 'flex',
    },
    formGroup: {
      width: '50%',
      margin,
    },
    uploadContainer: {
      display: 'flex',
    },
    imgDiv: {
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
      width: 100,
      height: 100,
      marginLeft: '2rem',
      backgroundSize: 'cover',
      '&:hover': {
        opacity: '.5',
        content: 'Remove',
      },
    },
  })
);

const initialState = {
  title: '',
  content: '',
  name: '',
  email: '',
  phone: '',
  company: '',
  destination: '',
};

const CreateTicket = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { destination } = queryString.parse(useLocation().search);
  
  const intl = useIntl();
  const showProgressBar = useProgressBar();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyle();

  const onCreateTicket = async (e) => {
    e.preventDefault();

    showProgressBar();
    const form = { ...state };

    try {
      const { data: response } = await Http.post('/tickets', { ...form });
      enqueueSnackbar(intl.translate(response));
    } catch (e) {
      enqueueSnackbar(intl.translate('general.error'));
    } finally {
      showProgressBar(false);
    }
  };

  useEffect(() => {
    dispatch({ type: 'destination', payload: destination });
  }, [destination]);

  // Function to handle uploaded file
  const onFileChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = (upload) =>
      dispatch({ type: 'image', payload: upload.target.result });
    reader.readAsDataURL(file);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant="h5" component="h2">
          {intl.translate('ticket.pageTitle')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {intl.translate('ticket.pageDescription')}
        </Typography>
        <form autoComplete="off" onSubmit={onCreateTicket}>
          <div className={classes.container}>
            {true && (
              <div className={classes.formGroup}>
                <TextField
                  id="name"
                  label={intl.translate('ticket.name')}
                  onChange={(e) =>
                    dispatch({
                      type: e.currentTarget.id,
                      payload: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                />

                <TextField
                  id="email"
                  label={intl.translate('ticket.email')}
                  onChange={(e) =>
                    dispatch({
                      type: e.currentTarget.id,
                      payload: e.target.value,
                    })
                  }
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  id="phone"
                  label={intl.translate('ticket.phone')}
                  onChange={(e) =>
                    dispatch({
                      type: e.currentTarget.id,
                      payload: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                />

                <TextField
                  id="company"
                  label={intl.translate('ticket.company')}
                  onChange={(e) =>
                    dispatch({
                      type: e.currentTarget.id,
                      payload: e.target.value,
                    })
                  }
                  margin="normal"
                  fullWidth
                />
              </div>
            )}
            <div className={classes.formGroup}>
              <TextField
                id="title"
                label={intl.translate('ticket.title')}
                onChange={(e) =>
                  dispatch({
                    type: e.currentTarget.id,
                    payload: e.target.value,
                  })
                }
                margin="normal"
                fullWidth
              />

              <TextField
                id="content"
                required
                label={intl.translate('ticket.content')}
                onChange={(e) =>
                  dispatch({
                    type: e.currentTarget.id,
                    payload: e.target.value,
                  })
                }
                multiline
                rows="4"
                className={classes.textField}
                margin="normal"
                fullWidth
              />

              <div className={classes.uploadContainer}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="upload-input"
                  encType="multipart/form-data"
                  onChange={onFileChange}
                  type="file"
                />
                <label htmlFor="upload-input">
                  <IconButton variant="contained" component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>

                <div
                  onClick={() => dispatch({ type: 'image', payload: null })}
                  className={classes.imgDiv}
                  style={{ backgroundImage: `url(${state.image}` }}
                />
              </div>
            </div>
          </div>

          <Button
            className={classes.createButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            {intl.translate('ticket.createTicket')}
          </Button>
        </form>
      </Paper>
    </div>
  );
};

const reducer = (state, { type, payload }) => ({ ...state, [type]: payload });

export default CreateTicket;
