/* eslint-disable no-param-reassign */
import Excel from 'exceljs';
import htmlToText from 'html-to-text';
import { formatDate } from './date';

const getExcelWorkbook = (data, fetchPincode) => {
  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet('Releases');
  const shouldAddTerms = data.length <= 5;

  data.forEach(({ blNumber, terms, releases }) => {
    const row = sheet.addRow([`Bill of Lading: ${blNumber}`]);
    row.style = { ...row.style, font: { bold: true, name: 'Arial', size: 10 } };

    sheet.addRow([
      'Status',
      'Released on',
      'Valid from',
      'Valid till',
      'Container number',
      'Pick up terminal',
      'Turn -in Location',
      'Turn-in Reference',
      ...(fetchPincode ? ['Pincode'] : []),
    ]);

    releases.forEach((release) => {
      sheet.addRow([
        release.releaseStatus,
        formatDate(release.container.createdAt),
        formatDate(release.container.validFrom),
        formatDate(release.container.validUntil),
        release.container.containerNumber,
        release.container.pickupLocation,
        release.container.turnInLocation,
        release.container.turnInReference,
        ...(fetchPincode ? [release.pincode] : []),
      ]);
    });

    sheet.addRow([]);

    // Exclude terms when more than one BL
    if (shouldAddTerms) {
      addHtmlToSheet(sheet, terms);
    }

    sheet.addRow([]);
  });

  const widths = [10, 10, 10, 10, 15, 15, 15, 10, 15];

  addColumnsWidthToSheet(sheet, widths);

  return workbook;
};

const getFlatExcelWorkbook = (data, fetchPincode) => {
  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet('Releases');
  sheet.addRow([
    'Bill of lading',
    'Status',
    'Released on',
    'Valid from',
    'Valid till',
    'Container number',
    'Pick up terminal',
    'Turn -in Location',
    'Turn-in Reference',
    ...(fetchPincode ? ['Pincode'] : []),
  ]);

  data.forEach(({ blNumber, ...release }) => {
    sheet.addRow([
      blNumber,
      release.releaseStatus,
      formatDate(release.container.createdAt),
      formatDate(release.container.validFrom),
      formatDate(release.container.validUntil),
      release.container.containerNumber,
      release.container.pickupLocation,
      release.container.turnInLocation,
      release.container.turnInReference,
      ...(fetchPincode ? [release.pincode] : []),
    ]);
  });

  const widths = [15, 15, 10, 10, 10, 15, 15, 15, 15, 10];

  addColumnsWidthToSheet(sheet, widths);

  return workbook;
};

const addColumnsWidthToSheet = (sheet, widths = []) => {
  sheet.columns.forEach((column, i) => {
    column.width = widths[i];
    column.style = {
      alignment: { horizontal: 'center' },
      font: { name: 'Arial', size: 10 },
    };
  });
};

const addHtmlToSheet = (sheet, html) => {
  const text = convertHtmlTotext(html);
  const textLines = text.split('\n');

  textLines.forEach(
    (line) => !line.startsWith('[data:image') && sheet.addRow([line])
  );
};

const convertHtmlTotext = (html) => htmlToText.fromString(html, { wordwrap: 130 });

export { getExcelWorkbook, getFlatExcelWorkbook };
