import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Button } from '@material-ui/core';
import useIntl from './useIntl';
import useImprovedDialog from './useImprovedDialog';

const useTermsDialog = ({ onOk }) => {
  const [agreed, setAgreed] = useState(false);
  const { translate } = useIntl();
  const { showDialog, setDialog } = useImprovedDialog()

  const handleCheckbox = (event) => setAgreed(event.currentTarget.checked);

  const content = (
    <div>
      <img src="./assets/msc-yellow-banner.png" alt='MSC banner' style={{width:'100%'}}/>
      <h3>
        Terms and conditions governing the liability of the User of the Secure
        Container Release App (“SCRA”) towards the Carrier (MSC Mediterranean
        Shipping Company SA, Geneva, hereinafter referred to as ‘MSC’)
      </h3>
      <p>
        These terms and conditions apply to all use made by the User of the SCRA
        in respect of the release of containers shipped by MSC under the scope
        of MSC Original bills of lading and/or MSC Sea Waybills.
      </p>
      <p>
        The User acknowledges that by making use of the SCRA he has previously
        taken note of and accepts all terms and conditions of the MSC Original
        Bill of Lading and/or the MSC Sea Waybill , and that he considers
        himself bound by the said terms and conditions.
      </p>
      <p>
        The User expressly declares to accept being party to the contract of
        carriage embodied by either the MSC Original Bill of Lading or the MSC
        Sea Waybill , as a condition to the release of the container(s) to him
        under the SCRA.
      </p>
      <p>
        The User takes note that for his easy reference the up-to-date version
        of the terms and conditions of the MSC Original Bill of Lading and the
        MSC Sea Waybill is always available from MSC’s Website www.msc.com .
      </p>
      <p>
        Particularly the User confirms to:
        <ul>
          <li>
            Arrange or ensure proper customs clearance of the cargo prior to
            collect the container(s) from the terminal
          </li>
          <li>Check the availability of the container(s) prior to pick-up</li>
          <li>
            Return the container(s) empty and in clean condition, free from any
            damage, failing of which he will indemnify MSC for the costs
            incurred as a result thereof
          </li>
          <li>
            Collect the container(s) from the terminal and redeliver the
            container(s) empty to the place designated by MSC within the free
            time allocated and known by the User, failing of which User will
            indemnify MSC for the demurrages and storage charges incurred as per
            tariffs known by the User
          </li>
        </ul>
      </p>
      <p><small>This message is shown because you receive container releases from MSC Belgium.</small></p>
    </div>
  );

  const actions = (
    <div style={controlsStyle}>
      <FormControlLabel
        control={<Checkbox onChange={handleCheckbox} />}
        label={translate('terms.agree')}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!agreed}
        onClick={onOk}
      >
        {translate('general.ok')}
      </Button>
    </div>
  );

  useEffect(() => {
    setDialog({ content, actions })
  }, [agreed])

  return { showDialog };
};

export default useTermsDialog;

const controlsStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: 10,
};
