import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Info from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import TMMenu from './TMMenu.jsx';
import { FormattedMessage } from 'react-intl';
import { NavLink } from "react-router-dom";
import { ROUTES } from '../../pages/routes.js';
import Icon from '@mdi/react';
import { mdiGiftOutline } from '@mdi/js';
import * as Subscriptions from '../../util/subscriptions';

class TMProfileIconMenu extends React.Component {

	state = {
		anchorEl: null,
	};

	/* sets the anchor element to the profile menu iconbutton */
	handleMenuOpen = event => {
		window.addToDataLayer('profilemenu', 'open')
		this.setState({ anchorEl: event.currentTarget });
	};
	/* clears the anchor element, making the menu disappear */
	handleMenuClose = () => {
		window.addToDataLayer('profilemenu', 'close')
		this.setState({ anchorEl: null });
	};

	getAccountType = () => {
		const { user } = this.props;
		if (user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_API) !== undefined) {
			return 'SCR API';
		}
		if (user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_PREMIUM_TRIAL) !== undefined) {
			return 'SCR Premium Free Trial';
		}
		if (user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_PREMIUM) !== undefined) {
			return 'SCR Premium';
		}
		if (user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_NONPAYING) !== undefined) { 
			if (user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_CLOUD_WALLET) !== undefined) { 
				return 'SCR Freemium';
			}
			if (user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_COMPANY_WALLET) !== undefined) { 
				return 'SCR Basic';
			}
		}
		return ''
	}

	render() {

		const { isAuthenticated, handleHelpClick, user } = this.props;
		const { anchorEl } = this.state;
		const { email, isAdmin = false, organization  } = user || {}

		return (
			<React.Fragment>
				{isAuthenticated &&
					<ClickAwayListener onClickAway={this.handleMenuClose}>
						<React.Fragment>
							<Button color="primary" onClick={this.handleMenuOpen}>
								<AccountCircle />
							</Button>
							<Button color="primary" onClick={handleHelpClick}>
								<HelpIcon />
							</Button>
							<TMMenu
								id='profile-menu'
								anchorEl={anchorEl}
								handleMenuClose={this.handleMenuClose}>
								<MenuItem disabled={true}><AccountCircle /> &nbsp; {email} </MenuItem>
								<MenuItem disabled={true}><Info /> &nbsp; {organization}</MenuItem>
								{ user.organizationRole !== 'carrier' &&
									<MenuItem disabled={true}>
										<Icon path={mdiGiftOutline} size={1} color='grey'/> &nbsp; 
										Account type: { this.getAccountType() }
									</MenuItem>
								}
								{
									ROUTES
										.filter(route =>   
											route.category === 'profile' && 
											(route.adminOnly ? isAdmin : true) &&
											(route.requiresFeature ? user.features[route.requiresFeature] : true)
										)
										.map((route, key) => 
											<div key={key}>
												<Divider />
												<MenuItem>
													<NavLink {...route.path ? { to: route.path } : {}} style={{ textDecoration: 'none' }} onClick={() => { route.onClick && route.onClick(); this.handleMenuClose(); }}>
														<FormattedMessage id={route.title} />
													</NavLink>
												</MenuItem>
											</div>
										)
								}
							</TMMenu>
						</React.Fragment>
					</ClickAwayListener>
				}
			</React.Fragment>
		);
	}
}

TMProfileIconMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
};

export default (TMProfileIconMenu);

