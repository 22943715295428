import { Http } from '../../axios';

export const assignReleases = async ({ type, identification, releaseAddresses, anonAddresses }) => {
  return await Http.update('releases/assignment/assign?provider=socketio', {
    type,
    identification,
    releaseAddresses,
    includeadr: anonAddresses
  })
};

export const unAssignReleases = async ({ releaseAddresses, anonAddresses }) => {
  return await Http.update('releases/assignment/unassign?provider=socketio', {
    releaseAddresses,
    includeadr: anonAddresses
  })
};

export const getReleaseAuditTrail = async ({ releaseAddresses, anonAddresses, connections }) => {
  return await Http.post('audit-trail', {
    connections,
    releaseAddresses,
    includeadr: anonAddresses
  });
};
