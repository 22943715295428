import React, { useReducer, createContext, useContext } from 'react';
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import useIntl from '../hooks/useIntl';

import reducer from '../util/reducer';

const initialState = {
  title: '',
  content: '',
  onOk: () => {},
  open: false,
  maxWidth: 'md'
};

const AlertDialogContext = createContext();

const AlertDialogProvider = ({ children }) => {
  const { translate } = useIntl();
  const [{ title, content, open, maxWidth }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const showAlert = (title, content, maxWidth='md') =>
    dispatch({ payload: { title, content, maxWidth, open: true } });

  const hideAlert = () => dispatch({ payload: { open: false } });

  return (
    <AlertDialogContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      <Dialog open={open} maxWidth={maxWidth} fullWidth={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Box display="flex" flexDirection="row-reverse">
            <Button onClick={hideAlert}>{translate('general.ok')}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </AlertDialogContext.Provider>
  );
};

const useAlertDialog = () => useContext(AlertDialogContext);

export { AlertDialogProvider, useAlertDialog };