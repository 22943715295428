import app from '../feathers';
import logSentry from './logSentry';

const getPincode = async (releaseAddresses, organizationName) => {

  const pincodeResults = await window.walletApi.getPincodeForReleases( releaseAddresses, { from: organizationName } )

  if (pincodeResults) {
    const pincodeSuccesses = pincodeResults.filter(res => res.pincode).map(res => ({releaseAddress: res.address}))

    if (pincodeSuccesses.length) {
      try {
        await app.service('pincode-log').create(pincodeSuccesses);
      } catch (e) {
        logSentry(e);
      }
    }

    return pincodeResults;
  } else {
    throw new Error("getpincode.error.general")
  }
};

export default getPincode;
