import React from 'react';
import Chip from '@material-ui/core/Chip';
import { detect } from 'detect-browser';
import useIntl from '../hooks/useIntl';

const browser = detect();

const SUPPORTED_BROWSERS = ['chrome', 'edge', 'edge-chromium', 'safari', 'firefox'];

const BrowserSupport = () => {
  const { translate } = useIntl();

  if (browser) {
    return (
      !SUPPORTED_BROWSERS.includes(browser.name) && (
        <div className="browser-support">
          <Chip label={translate('browser.notSupported')} />
        </div>
      )
    );
  }

  return null;
};

export default BrowserSupport;