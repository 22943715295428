import React from 'react';
import Chart from "react-google-charts";

const PieChart = ({loader, data, chartEvents, title, colors, id}) => {
		    
	return (
		<Chart
			chartType="PieChart"
	    graphID={`piechart-${id}`}
			loader={loader}
			data={data}
			chartEvents={chartEvents}
			options={{
	      title: title,
	      height: '235px',
	      legend: {
			    position: 'bottom',
			    alignment: 'center',
			    textStyle: {
			      color: '#000',
			      fontSize: 12
			    }
			  },
	      pieSliceText:'percentage',
	      is3D: true,
	      colors: colors
	    }}
		/>
	);
}

export default PieChart;