/* eslint-disable react/prop-types, react/jsx-handler-names */
import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import TodayIcon from '@material-ui/icons/Today';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

class TMDateInput extends React.Component {
  render() {
    const {
      value,
      mask = '99/99/9999',
      helperText = 'dd/mm/yyyy',
      className = '',
      readOnly = false,
      variant = 'outlined',
      onChange,
      onBlur,
      setToday,
      ...restProps
    } = this.props;

    return (
      <InputMask
        mask={mask}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        readOnly={readOnly}
      >
        {() => (
          <TextField
            margin="normal"
            type="text"
            variant={variant}
            className={className}
            value={value}
            helperText={helperText}
            InputProps={{
              endAdornment: setToday && !readOnly && (
                <InputAdornment position="end">
                  <IconButton aria-label="Set to today" onClick={setToday}>
                    <TodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...restProps}
          />
        )}
      </InputMask>
    );
  }
}

TMDateInput.propTypes = {
  value: PropTypes.string.isRequired,
  mask: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setToday: PropTypes.func,
};

export default TMDateInput;
