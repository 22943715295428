import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import client from '../feathers';
import PieChart from "../lib/PieChart";
import useIntl from '../hooks/useIntl';

const NUMBER_OF_CHARTS = 4;

const useStyles = makeStyles((theme) => ({
  chartWrapper: {
    width:`${100/NUMBER_OF_CHARTS}%`, 
  },
}));

const ChartCanvas = ({ showDashboard, anonAddresses, handleCanvasClick }) => {
	
  const { translate } = useIntl();
  const [charts, setCharts] = useState([]);
	const classes = useStyles();
  
	const chartEvents = [{
	  eventName: 'select',
	  callback: ({ chartWrapper }) => {
			const chart = chartWrapper.getChart()
			const selection = chart.getSelection();
			if (selection.length >0) {
				const dataTable = chartWrapper.getDataTable();
				const chartId = chart.container.id.replace("piechart-", "");
				// dataTable.getValue: 0 = translated unit, 1 = number, 2 = original unit, used for filtering
				handleCanvasClick(
					dataTable.getValue(selection[0].row, 2), 
					chartId,
					dataTable.getValue(selection[0].row, 0), 
				);
		  }
		}
	}];

  useEffect(() => { 
  	const fetchDashboard = async () => {
			try {
				const response = await client.service('dashboard').find({
					query: { 
		        addresses: anonAddresses,
					}
				});
				
				// pagination is turned off, hence an array is returned
				if (response.length > 0) {		
					let _charts = [];
					for (let idx=0; idx<NUMBER_OF_CHARTS; idx++) {
						const title = response.find(item => parseInt(item.forChart) === idx);
						if (title) {
							const data = [['Status', 'Percentage', 'Label']].concat(
								response
									.filter(item => parseInt(item.forChart) === idx)
									.map(data => {
										let unit = !!data.translationKey ? translate(`${data.translationKey}.${data.unit}`) : data.unit;
										return [unit, parseInt(data.number), data.unit] 
									})
							);
							_charts.push({ data, title: title.chartTitle })
						}
					}
					setCharts(_charts);
				}
			} catch (error) {
				console.error(error);
			}
		}

    showDashboard && fetchDashboard();
  }, [showDashboard])

  return (
		<React.Fragment>
		{
			charts.map((chart, idx) => 
				<div className={classes.chartWrapper} key={idx}>
					<PieChart
						key={chart.title}
						loader={translate("general.progress.title")}
						data={chart.data}
						chartEvents={chartEvents}
						title={chart.title}
						colors={['#CDA24D','#5C9EAD', '#7D4600', '#326273', '#2E4756', '#c2823c', '#fcf4e3']}
						id={idx}
					/>
				</div>
			)
		}    
		</React.Fragment>
	);
}

export default ChartCanvas
