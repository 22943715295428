import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
// import Draggable from 'react-draggable';

const classes = theme => ({
  progress: {
    margin: theme.spacing(1)
  },
  hidden: {
    display: 'none',
  },
  draggable: {
    // cursor: 'move'
  }
});

// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }

export const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' {...props} ref={ref} />
))

function PaperComponent(props) {
  return (<Paper {...props} style={{overflowY: 'visible'}}/>);
  // return (
  //   <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
  //     <Paper {...props} />
  //   </Draggable>
  // );
}

class TMDialog extends React.Component {

  state = {
    submitting: false,
  }

  handleSubmit = async () => {
    this.setState({submitting: true})
    await this.props.handleSubmit();
    this.setState({submitting: false})
  }

  render() {

    const { classes, dialogOpen, handleDialogClose, title, handleSubmit, children, 
            btnLabel=this.props.title, maxWidth="sm", drawerOpen, handleDrawerOpen, 
            showTopClose=true, showBottomClose=true, fullScreen=false, zIndex=1300,
            // disableBackdropClick=false, disableEscapeKeyDown=false, 
          } = this.props;
    const { submitting } = this.state;

    return (
      <Dialog
        fullWidth={fullScreen ? false : true}
        maxWidth={fullScreen ? undefined : maxWidth}
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        // disableBackdropClick={disableBackdropClick}
        // disableEscapeKeyDown={disableEscapeKeyDown}
        style={{zIndex: zIndex}}
      >
        <DialogTitle id="form-dialog-title" className={classes.draggable}>
          {title}
          <Tooltip title="Close">
            <React.Fragment>
              { handleDrawerOpen !== undefined && 
                <IconButton
                  aria-label="Open HELP" onClick={() => handleDrawerOpen('login')} 
                  style={{float: 'right'}} className={classNames(drawerOpen && classes.hidden)}
                >
                  <HelpIcon />
                </IconButton>
              }
              { showTopClose &&
                <IconButton aria-label="Close" onClick={handleDialogClose} style={{float: 'right'}}>
                  <CloseIcon/>
                </IconButton>
              }
            </React.Fragment>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          { children }
        </DialogContent>
        <DialogActions>
          <React.Fragment>
            { showBottomClose &&
              <Button onClick={handleDialogClose} color="secondary">
                { handleSubmit ? "Cancel" : "Close" }
              </Button>
            }
            { handleSubmit &&
              <Button onClick={this.handleSubmit} color="primary">
                {btnLabel}
                <CircularProgress size={12} className={ classNames(classes.progress, { [classes.hidden]: !submitting }) }/>
              </Button>
            }
          </React.Fragment>
        </DialogActions>
      </Dialog>
    );
  }
}

TMDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  handleSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  btnLabel: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default withStyles(classes)(TMDialog);
