import React from 'react';
import TMDataTable from '../../../lib/Table/TMDataTable';
import useIntl from '../../../hooks/useIntl';

const ReleasesBarges = ({ barges, onSelect }) => {
  const { translate } = useIntl();

  const columns = ['name', 'eninumber'].map((column) => translate(`barge.${column}`));
  
  const data = barges
    .filter(b => b.isActive)
    .map(({ name, eniNumber }) => 
      [name, eniNumber]
    );

  const options = {
    sort: false,
    print: false,
    filter: false,
    search: true,
    download: false,
    viewColumns: false,
    selectableRows: 'single',
    selectableRowsOnClick: false,
    onSelectionChange: ([selected]) => {
      if (selected) {
        const identification = selected.pop();
        onSelect(identification);
      } else {
        onSelect(undefined)
      } 
    },
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
  };

  return (
    <div className="no-radius-table">
      <TMDataTable columns={columns} data={data} options={options} />
    </div>
  )
};

export default ReleasesBarges;
