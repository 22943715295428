import types from './drivers-and-barges.types';

export const INITIAL_STATE = {
  loading: false,
  drivers: [],
  identification: undefined,
  barges: [],
  activeTab: 'driver',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_DRIVERS:
      return { ...state, drivers: action.payload };

    case types.SET_IDENTIFICATION:
      return { ...state, identification: action.payload };

    case types.SET_BARGES:
      return { ...state, barges: action.payload };

    case types.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };

    default:
      return state;
  }
};
