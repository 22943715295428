import React, { useReducer, useEffect } from 'react';
import client from '../../feathers';
import TMDialog from '../../lib/Dialog/TMDialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useIntl from '../../hooks/useIntl';
import { assignReleases, unAssignReleases } from '../../services/releases';
import AssignmentReport from '../../components/AssignmentReport';
import CustomTabs from '../CustomTabs';
import ReleasesDriver from './drivers/drivers';
import ReleasesBarges from './barges/barges';
import driversAndBargesTypes from './drivers-and-barges.types';
import { reducer, INITIAL_STATE } from './drivers-and-barges.reducer';
import { useAlertDialog } from '../../context/AlertDialogProvider';
// import { useDrivers } from '../../hooks/useDrivers';
// import { useBarges } from '../../hooks/useBarges';
import useSentry from '../../hooks/useSentry';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import useAuth from '../../hooks/useAuth';
import '../style.css';

const ReleasesDriversAndBarges = ({
  selectedReleaseAddresses,
  onCancel,
  driversAndBarges: { dialog: show, type },
  refreshBills,
  anonAddresses
}) => {
  const { translate } = useIntl();
  const { user } = useAuth();
  const { showAlert } = useAlertDialog();
  const logSentry = useSentry();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => { 
    show && handleDriversAndBarges() 
  }, [show])

  const handleDriversAndBarges = async () => {
    const drivers = await client.service('drivers').find()
    const barges = await client.service('barges').find()

    dispatch({ type: 'SET_DRIVERS', payload: drivers.data })
    dispatch({ type: 'SET_BARGES', payload: barges.data })
  }

  const handleIdentification = (identification) => {
    dispatch({
      type: driversAndBargesTypes.SET_IDENTIFICATION,
      payload: identification,
    });
  }

  const handleOnTabChange = (result) => {
    dispatch({
      type: driversAndBargesTypes.SET_ACTIVE_TAB,
      payload: result === 1 ? 'barge' : 'driver',
    });
  };

  const handleClick = async (operation) => {
    let result, walletResult;
    onCancel();

    try {
      showProgressDialog();
      if (operation === 'assign') {
        // Backend first, will return successfully processed releasAddresses
        result = await assignReleases({
          identification: state.identification,
          type: state.activeTab,
          releaseAddresses: selectedReleaseAddresses,
          anonAddresses
        });
        // console.log("assign result", result.data)
        if (result.data.eligible_releases.length) {
          // use successfully processed releasAddresses for chain operation

          walletResult = await window.walletApi.assignReleases(
            result.data.eligible_releases.map(({address,version}) => ({address,version})), 
            { identification: state.identification, type: state.activeTab },
            { from: user.organization }
          );
        }
      } else {
        result = await unAssignReleases({ releaseAddresses: selectedReleaseAddresses, anonAddresses });
        // console.log("unassign result", result)

        if (result.data.eligible_releases.length) {
          walletResult = await window.walletApi.unassignReleases(
            result.data.eligible_releases.map(({address,version}) => ({address,version})), 
            { from: user.organization }
          );
        }
      }
      // console.log("walletResult", walletResult)
      if (walletResult) {
        result.data.walletErrors = walletResult.filter(r => r.error);
        const walletSuccesses = walletResult.filter(r => !r.error).map(r => r.address);
        result.data.eligible_releases = result.data.eligible_releases.filter(r => walletSuccesses.includes(r.address))
      }

      showAlert(
        translate(`assignmentReport.${operation}.results`),
        <AssignmentReport
          data={result.data} 
          operation={operation} 
        />
      );
    } catch (error) {
      console.error(error)
      logSentry(error);
      showAlert(translate('general.error'), error.message);
    } finally {
      hideProgressDialog();
      refreshBills(true, false);
    }
  };

  return (
    <TMDialog
      key={type}
      title={translate(`blActions.${type}`)}
      dialogOpen={show}
      handleDialogClose={onCancel}
      maxWidth="md"
      showBottomClose={false}
    >
      <React.Fragment>
        <Typography variant="body2">
          {translate(`driverAndBarges.${type}.dialog.line_1`, {
            numberOfReleases: selectedReleaseAddresses.length || 0,
          })}
        </Typography>
        {type === 'assign' && (
          <CustomTabs
            onTabChange={handleOnTabChange}
            content={[
              {
                label: 'Drivers',
                component: (
                  <ReleasesDriver
                    drivers={state.drivers}
                    onSelect={handleIdentification}
                  />
                ),
              },
              {
                label: 'Barges',
                component: (
                  <ReleasesBarges
                    barges={state.barges}
                    onSelect={handleIdentification}
                  />
                ),
              },
            ]}
          />
        )}
        <div style={{padding: '25px 10px 0px 10px'}}>
          <Button variant="contained" color="primary" disabled={type === 'assign' && state.identification === undefined} onClick={() => handleClick(type)}>
            {translate(`blActions.${type}`)}
          </Button>
          &nbsp;
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {translate('general.cancel')}
          </Button>
        </div>
      </React.Fragment>
    </TMDialog>
  );
};

export default ReleasesDriversAndBarges;
