import React, { createContext, useState } from 'react';
import SideDrawer from '../components/SideDrawer';

const DrawerContext = createContext();

const drawerWidth = '25vw';

const DrawerProvider = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [page, setPage] = useState();
  const [component, setComponent] = useState();
  const [title, setTitle] = useState();

  const toggleDrawer = () => {
    const show = !showDrawer;
    setShowDrawer(show);
    if (!show) setPage(null);
  };

  const openDrawer = (page) => {
    setShowDrawer(true);
    setPage(page);
  };

  const showComponent = (component, title) => {
    setShowDrawer(true);
    setComponent(component);
    setTitle(title);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
    setComponent(null);
    setPage(null);
    setTitle(null);
  };

  return (
    <DrawerContext.Provider
      value={{
        drawerWidth,
        toggleDrawer,
        showDrawer,
        openDrawer,
        closeDrawer,
        showComponent,
      }}
    >
      {children}
      <SideDrawer
        width={drawerWidth}
        drawerOpen={showDrawer}
        handleDrawerClose={closeDrawer}
        page={page}
        component={component}
        title={title}
      />
    </DrawerContext.Provider>
  );
};

export { DrawerContext, DrawerProvider };
