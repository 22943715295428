import React, {useReducer} from 'react';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider } from 'notistack';
import reducer from './util/reducer';

import Layout from './pages/Layout';
import messages_en from './translations/en.json';
import messages_fr from './translations/fr.json';
import messages_nl from './translations/nl.json';
import message_de from './translations/de.json';
import message_it from './translations/it.json';
import TMWithRoot from './lib/Theme/TMWithRoot';
import { ProgressBarProvider } from './context/ProgressBarProvider';
import { LanguageProvider, LanguageContext } from './context/LanguageProvider';
import { AuthenticationContext, AuthenticationProvider } from './context/AuthenticationProvider';
import { DrawerProvider } from './context/DrawerProvider';
import { DialogProvider } from './context/DialogProvider';
import { ImprovedDialogProvider } from './context/ImprovedDialogProvider';
import { ProgressDialogProvider } from './context/ProgressDialogProvider';
import { AlertDialogProvider } from './context/AlertDialogProvider';

import * as Sentry from "@sentry/react";

const languages = {
  de: message_de,
  en: messages_en,
  fr: messages_fr,
  it: message_it,
  nl: messages_nl,
};

const initialState = {
  loggedIn: false,
  loggedOut: false,
  feathersAuthenticate: false
}

const App = () => {
  console.log("REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);

  const [state, dispatch] = useReducer(reducer, initialState);

  const login = () => {
    dispatch({ payload: { loggedIn: true, loggedOut: false, feathersAuthenticate: true }});
  }

  const logout = () => {
    dispatch({ payload: { loggedOut: true }});
  }

  window.logoutApp = logout;

  const feathersAuthenticated = () => {
    dispatch({ payload: { feathersAuthenticate: false }});
  }

console.log("APP STATE")
console.log(state)

  if (state.loggedOut) {
  return (
    <Sentry.ErrorBoundary>
      <div>Logged out</div>
    </Sentry.ErrorBoundary>
  );
  } else {
  return (
    <Sentry.ErrorBoundary>
    <AuthenticationProvider/>
    </Sentry.ErrorBoundary>
  );
  }
};

export default TMWithRoot(App);
