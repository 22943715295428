import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import useIntl from '../../hooks/useIntl';

const TMDataTable = (props) => {
  const { translate } = useIntl();

  const {
    title = '',
    columns,
    data,
    setRowProps,
    customSort,
    options: opts,
    onRowClick,
    className
  } = props;

  const [{ rowsSelected }, dispatch] = useReducer(reducer, initialState);

  const options = {
    filterType: 'multiselect',
    setRowProps,
    onRowClick,
    selectableRows: 'none',
    selectToolbarPlacement: 'none',
    rowsSelected,
    onRowSelectionChange: (selectedRows, allRows) => {
      const rowsSelected = allRows.map((r) => r.index);
      dispatch({ type: 'SET', payload: { rowsSelected } });
      opts.onSelectionChange &&
        opts.onSelectionChange(
          data.filter((row, i) => rowsSelected.includes(i))
        );
    },
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
      pagination: {
        next: translate('dataTable.controls.next'),
        previous: translate('dataTable.controls.previous'),
        rowsPerPage: translate('dataTable.controls.rowsPerPage'),
        displayRows: translate('dataTable.controls.displayRows')
      },
      toolbar: {
        search: translate('dataTable.controls.search'),
        downloadCsv: translate('dataTable.controls.downloadCsv'),
        print: translate('dataTable.controls.print'),
        viewColumns: translate('dataTable.controls.viewColumns'),
        filterTable: translate('dataTable.controls.filterTable'),
      },
      ...(opts && opts.textLabels),
    },
    ...opts,
  };

  if (customSort !== undefined) {
    options.customSort = customSort;
  }

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
      className={className}
    />
  );
};

const initialState = {
  rowsSelected: [],
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET':
      return { ...state, ...payload };

    default:
      return { ...state };
  }
};

TMDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.bool,
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.bool,
          ])
        ),
      ])
    )
  ).isRequired,
};

export default TMDataTable;
