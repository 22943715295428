import React, { createContext, useReducer, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  LinearProgress,
} from '@material-ui/core';
import reducer from '../util/reducer';

import useIntl from '../hooks/useIntl';

const ProgressDialogContext = createContext();

const initialState = {
  open: false,
  message: '',
  title: '',
};

const ProgressDialogProvider = ({ children }) => {
  const [{ open, message, title }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const { translate } = useIntl();

  const showProgressDialog = (
    title = translate('general.progress.title'),
    message = translate('general.progress.text')
  ) => dispatch({ payload: { title, message, open: true } });
  const hideProgressDialog = () => dispatch({ payload: { open: false } });

  return (
    <ProgressDialogContext.Provider
      value={{ showProgressDialog, hideProgressDialog }}
    >
      {children}
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box>
            {message}
            <Box my={3}>
              <LinearProgress />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ProgressDialogContext.Provider>
  );
};

const useProgressDialog = () => useContext(ProgressDialogContext);

export { ProgressDialogProvider, useProgressDialog };
