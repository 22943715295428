import React from 'react';
import useIntl from '../hooks/useIntl';
import { parseError } from '../util/release'

const TransferRevokeReport = ({ data, type, bulkValidationErrors=''}) => {
  const { translate } = useIntl();

  // type = transfer | revoke
  return (
    <div>
      <p>{translate(`transferReport.${type}.summary`)}</p>
      {
        Object.entries(data).map(([key, value]) => {
          return value.length === 0 || (bulkValidationErrors.length && type === "transfer" && key === "pincode_fetched_releases") // repeated in bulkValidationErrors
            ? null
            : <div key={key}>
                {translate(`transferReport.${type}.${key}`)}
                <ul>
                  {value.map((release, i) => {
                    
                    {/*console.log('report about release', release)*/}
                    if (release.container) {
                      return  <li key={i}>
                                {release.container.containerNumber}
                                {release.error && `: ${parseError(release.error) || release.error.trim().replace(/[^\x20-\x7E]/g, '')}`}
                                { /* replace non-printable ascii chars returned by wallet: 
                                     https://www.w3resource.com/javascript-exercises/javascript-string-exercise-32.php */ }
                              </li>
                    }
                    if (release.address) {
                      return  <li key={i}>
                                <code>{release.address}</code>
                                {release.error && `: ${parseError(release.error) || release.error.trim().replace(/[^\x20-\x7E]/g, '')}`}
                              </li>
                    }
                    return <React.Fragment/>
                  })}
                </ul>
              </div>
        })
      }
      { bulkValidationErrors.length
        ? <div key='bulkValidationErrors'>
            Bulk Operations validation errors
            <ul>
              { bulkValidationErrors.map((error, i) => <li key={i}>{error.msg}</li>) }
            </ul>
          </div>
        : <span/>
      }
    </div>
  );
};

export default TransferRevokeReport;