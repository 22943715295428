import React, { useReducer, useEffect } from 'react';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import XLSX from 'xlsx';
import { useSnackbar } from 'notistack';

import TMDialog from '../../lib/Dialog/TMDialog';
import TMForm from '../../lib/Form/TMForm';
import useIntl from '../../hooks/useIntl';
import useFlatExcel from '../../hooks/useFlatExcel';
import NMoTDialog from '../NMoT/NMoTDialog';
import { types as NMoTTypes } from '../NMoT/types';

import client from '../../feathers';
import types from './types';
import TransferRevokeDialog from '../TransferRevokeDialog';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import { useAlertDialog } from '../../context/AlertDialogProvider';
import useSentry from '../../hooks/useSentry';

import { getAnonAddresses } from '../../util/connections';
import { beforeUnloadListener } from '../../util/release';
import { initialState, reducer } from './reducer';
import { filterContainers, createError, split, calculateTotal, mapForExcel, createWarning } from './util';

const BulkOperationsDialog = ({
  handleDialogClose,
  open,
  user,
  doTransfer,
  refreshBills
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { translate } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const logSentry = useSentry()
  const downloadExcel = useFlatExcel();

  useEffect(() => {
    const validateContainers = async () => {
      let { uploadedContainers, anonAddresses } = state;
      if (!uploadedContainers.length) return;
      enqueueSnackbar(
        translate('fileUpload.complete', {
          numberOfReleases: state.total,
        }),
      );
      if (!anonAddresses.length) {
        anonAddresses = await getAnonAddresses(user);

        dispatch({
          type: types.SET_ANON_ADDRESSES,
          payload: anonAddresses,
        });
      }

      const [blNumber, containerNumber] = split(uploadedContainers);

      const { data: existingContainers } = await client
        .service('containers')
        .find({
          query: {
            blNumber,
            containerNumber,
            addresses: anonAddresses,
          },
        });

      const result = filterContainers({
        uploadedContainers,
        existingContainers,
        ownerAddresses: anonAddresses,
      });

      dispatch({
        type: types.SET_FILTERED_CONTAINERS,
        payload: result,
      });

      closeSnackbar()
    };
    validateContainers();
  }, [state.uploadedContainers]);

  useEffect(() => {
    dispatch({
      type: types.SET_ERROR,
      payload: createError(state),
    });

    dispatch({
      type: types.SET_WARNING,
      payload: createWarning(state),
    });

    dispatch({
      type: types.SET_TOTAL_ERRORS,
      payload: calculateTotal(state),
    });
  }, [
    state.eligible,
    state.notFound,
    state.invalid,
    state.transferred,
    state.pincodeFetched,
    state.pickedUp
  ]);

  const resetForm = () => dispatch({ type: types.RESET_FORM });

  const resetAndClose = () => {
    window.addToDataLayer('bulk-operations', 'close');
    handleDialogClose(resetForm);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {
          cellDates: true,
          cellText: false,
          type: 'array',
        });
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        data = XLSX.utils.sheet_to_json(first_worksheet, {
          header: 1,
          blankrows: false,
        });
        // skip the header row
        data.shift();
        dispatch({
          type: types.SET_TOTAL,
          payload: data.length || 0,
        });
        enqueueSnackbar(
          translate('fileUpload.complete', {
            numberOfReleases: data.length || 0,
          }),
          { autoHideDuration: 3000 }
        );

        const uploadedContainers = data.map(([blNumber, containerNumber]) => ({
          blNumber,
          containerNumber,
        }));

        dispatch({
          type: types.SET_UPLOADED_CONTAINERS,
          payload: uploadedContainers,
        });
      };
      reader.readAsArrayBuffer(files[0]);
    }

    dispatch({ type: types.SET_FORM_VALUES, payload: { [name]: value } });
  };
  
  const handleBulkOperations = async () => {
    handleDialogClose();
    if (!state.eligible.length) return;
    
    try {
      showProgressDialog();
      window.addToDataLayer('bulk-operations', 'transfer-start');

      const selectedReleases = state.eligible.map(({ releaseAddress }) => releaseAddress);

      dispatch({ type: types.SET_SELECTED_RELEASES, payload: selectedReleases });
      dispatch({ 
        type: types.SET_BULK_VALIDATION_ERRORS, 
        payload: state.error 
                  ? state.error.concat(state.warning ? state.warning : []) 
                  : state.warning 
                    ? state.warning 
                    : [] 
      });
      dispatch({ type: types.SET_TRANSFER_DIALOG, payload: true });

      refreshBills();
      resetForm();
    } catch (error) {
      logSentry(error);
      showAlert(error.message);
    } finally {
      hideProgressDialog();
    }
  };

  const handleDownloadExcel = async () => {
    if (!state.eligible.length) return;

    try {
      showProgressDialog();
      
      window.addToDataLayer('bulk-operations', 'download-excel');
      window.addEventListener("beforeunload", beforeUnloadListener);
      console.log("added EventListener");

      const selectedReleases = state.eligible.map(({ releaseAddress }) => releaseAddress);
      dispatch({ type: types.SET_SELECTED_RELEASES, payload: selectedReleases });
      if (user.features.canNmot) {
        dispatch({ type: NMoTTypes.SHOW_NMoT });
      }

      const data = mapForExcel(state.eligible);
      await downloadExcel(data, true);
      showAlert(translate("bulkoperations.download.complete.title"), translate("bulkoperations.download.complete.text"));

      refreshBills()
      resetAndClose();

    } catch (error) {
      logSentry(error);
      showAlert(error.message);
    } finally {
      hideProgressDialog();
      window.removeEventListener("beforeunload", beforeUnloadListener);
      console.log("removed EventListener");
    }
  };

  return (
    <React.Fragment>
      <TMDialog
        key="bulk-operations"
        title="Bulk Operations"
        dialogOpen={open}
        handleDialogClose={resetAndClose}
        maxWidth={user.features.canBulkOperations ? 'xl' : 'md'}
        showBottomClose={false}
      >
        <Grid container spacing={5}>
        { user.features.canBulkOperations
          ?
            <React.Fragment>
              <Grid item xs={6}>
                <TMForm
                  object={state.form}
                  handleChange={handleChange}
                  handleSubmit={handleBulkOperations}
                  submitLabel={translate('blActions.transfer')}
                  enableSubmitCondition={user.features.canTransferRevoke && state.eligible.length > 0}
                  excelLabel={translate('dataTable.controls.downloadExcel')}
                  handleExcel={handleDownloadExcel}
                  enableExcelCondition={state.eligible.length > 0}
                  handleCancel={resetAndClose}
                  cancelLabel={translate('general.cancel')}
                  fields={[
                    {
                      field: 'excelName',
                      label: 'Upload a file',
                      required: true,
                      type: 'file',
                      accept: 'xls, xlsx',
                      variant: 'filled',
                      fullWidth: true,
                      helperText: <a href='/assets/Bulk-operations-example.xlsx' target='_new'>{ translate('bulkoperations.download.sample') }</a>
                    },
                  ]}
                >
                  <React.Fragment>
                    <Grid item sm={2} xs={12}></Grid>
                    <Grid item sm={10} xs={12}>
                      <p>
                        { translate('bulkoperations.readfromfile', { total: state.total, totalErrors: state.totalErrors}) }
                      </p>
                      <TextField
                        variant="outlined"
                        value={
                          state.error
                          ? state.error.concat(state.warning ? state.warning : []).map(e => e.msg).sort((a, b) => a > b ? 1 : a < b ? -1 : 0).join("\n") 
                          : state.warning 
                            ? state.warning.map(e => e.msg).sort((a, b) => a > b ? 1 : a < b ? -1 : 0).join("\n") 
                            : ''
                        }
                        multiline
                        rows={10}
                        fullWidth
                      />
                      <FormHelperText>
                        {translate('bulkoperations.please.correct')}
                      </FormHelperText>
                    </Grid>
                    <Grid item sm={2} xs={12}></Grid>
                    <Grid item sm={10} xs={12}>
                      <p>{translate('bulkoperations.please.choose')}</p>
                    </Grid>
                  </React.Fragment>
                </TMForm>
              </Grid>
              <Grid item xs={6}>
                <iframe title="bulkopframe" scrolling="no" style={{border:0, width: '100%', height: '650px'}} id="bulkopframe" src="https://www.securecontainerrelease.com/iframewebapp" />
              </Grid>
            </React.Fragment>
          :
            <Grid item xs={12}>
              <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '275px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-bulk-operations" />
            </Grid>
          }
        </Grid>
      </TMDialog>

      <TransferRevokeDialog
        show={state.transferDialog}
        onTransfer={(org) => {
          doTransfer('transfer', org, state.selectedReleases, false, state.bulkValidationErrors);
          dispatch({ type: types.SET_TRANSFER_DIALOG, payload: false });
          window.addToDataLayer('bulk-operations', 'transfer-end');
          if (user.features.canNmot) { 
            dispatch({ type: NMoTTypes.SHOW_NMoT }); 
          }
        }}
        onCancel={() => {
          window.addToDataLayer('bulk-operations', 'transfer-cancel');
          dispatch({ type: types.SET_TRANSFER_DIALOG, payload: false }) }
        }
        selectedReleaseAddresses={state.selectedReleases}
      />

      <NMoTDialog 
        show={state.NMoT.dialog}
        selected={state.NMoT.selected}
        selectedReleaseAddresses={state.selectedReleases} 
        onSelection={(selected) => { 
          dispatch({type: NMoTTypes.SET_NMoT, payload: selected})  
        }} 
        // onCancel={() => dispatch({type: NMoTTypes.CANCEL_NMoT})}
        onCancel={(event, reason) => { if (!['escapeKeyDown', 'backdropClick'].includes(reason)) { dispatch({type: NMoTTypes.CANCEL_NMoT}) } }}      />

    </React.Fragment>
  );
};

export default BulkOperationsDialog;
