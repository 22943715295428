import * as Sentry from '@sentry/react';

const useSentry = () => {
  const logError = (e) => {
    console.error(e);
    Sentry.captureException(e);
  };

  return logError;
};

export default useSentry;
