import React, { useReducer } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch as RouterSwitch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import TMAppBar from '../lib/AppBar/TMAppBar';
import { ROUTES } from './routes';
import useAuth from '../hooks/useAuth';
import SetLocalStorageVars from '../components/SetLocalStorageVars';
import { isTokenValid } from '../util/token';
import useDrawer from '../hooks/useDrawer';
import TMDialog from '../lib/Dialog/TMDialog';
import reducer from '../util/reducer';
import useIntl from '../hooks/useIntl';
import SRPForm from '../components/SRPForm';
import Switch from '@material-ui/core/Switch';
import client from '../feathers';
import superagent from "superagent";

const initialState = {
  openEula: true,
  eulaAccepted: false,
  latestEulaId: undefined,
  loggedIn: false
};

const drawerWidth = '30%';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      fontFamily: 'Roboto',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: '12vh'
    },
    appBarShift: {
      width: `calc(100vw - ${drawerWidth})`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
      overflow: 'auto',
      height: '88vh',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
  })
);

const Layout = (props) => {
  const classes = useStyles();
  const { accessToken, user } = useAuth();
  const { showDrawer, toggleDrawer } = useDrawer();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { translate } = useIntl();

  const helpClicked = () => toggleDrawer();

  const handleChange = (e) => {
    dispatch({ payload: { 
      [e.target.name]: e.target.checked,
      latestEulaId: user.eulaToSign.id
    }})
  };

  const handleAddSavedEula = async () => {
    const result = await client.service('users').patch(user.id, { eulaId: state.latestEulaId })
    window.location.reload();
  }

  const logout = async () => {
    window.addToDataLayer('eula-not-accepted', 'logout');
    localStorage.clear();
    sessionStorage.clear()

    // call logout
    try {


let {token} = window.walletApi._getWalletInfo();

let result = await superagent.post(process.env.REACT_APP_OAUTH_REVOKE_ENDPOINT)
                .send({token, client_id: "FnL4B39tKW4NwS9DMpnctQmynaZ1I0xd"})
    } catch (error) {
     console.log(error);
    } finally {
//    props.resetAuthentication();
//    dispatch({ payload: { openEula: false, loggedOut: true }});
    }

//    window.location.reload();
  }

  const login = () => {
    props.resetAuthentication();
//    dispatch({ payload: { openEula: false, loggedIn: true }});
  }


console.log(state);
console.log("check token valid");

  if (!state.loggedOut) {
console.log("logged out")
//	return </div>;
  }

  if (!isTokenValid()) {
console.log("return login whatever page");
    return <SetLocalStorageVars login={login}/>;
  }


  // SCRDEV-907 Check here whether user has to sign a new EULA
  if (user && user.hasOwnProperty('eulaToSign')) {
    return (
      <TMDialog
        key="sign-eula"
        title={translate('login.accept.eula')}
        dialogOpen={state.openEula}
        handleDialogClose={logout}
        maxWidth="sm"
        showBottomClose={false}
      >
        <p>
          { translate("eula.intro.1") }<br/>
          { translate("eula.intro.2") }
        </p>
        <SRPForm
          object={state}
          handleChange={handleChange}
          handleSubmit={handleAddSavedEula}
          submitLabel={translate("login.accept.eula.submit")}
          handleCancel={logout}
          cancelLabel={translate("general.cancel")}
          enableSubmitCondition={state.eulaAccepted}
          fields={[
            { field: 'eulaAccepted', label: translate("order.premium.label.eula"), componentType: Switch, fullWidth: true, required: true,
              placeholder: translate("order.premium.label.eula.accept"),
              helperText: <a href={user.eulaToSign.url} target='_new'>{ translate("order.premium.label.eula.read") }</a> 
            },
          ]}
        />
      </TMDialog>
    )
  }

console.log("df;ksdhglkf");
console.log(accessToken)

  return (
    <div className={classes.container}>
      <Router>
        <TMAppBar
          user={user}
          isAuthenticated={!!accessToken}
          handleHelpClick={helpClicked}
          className={clsx(classes.appBar, showDrawer && classes.appBarShift)}
          logoPath="../tmining-logo-mix.png"
        />
        <div
          className={clsx(classes.content, showDrawer && classes.contentShift)}
        >
          <RouterSwitch>
            {ROUTES.map((value) => 
{ 

	 if (value.title === "navbar.logout") {
	 }
	    return (
              <ProtectedRoute
                key={value.path}
                path={`/${value.path}`}
                component={value.component}
                isProtected={value.isProtected}
                exact
              />
            )
}
)}
          </RouterSwitch>
        </div>
      </Router>
    </div>
  );
};

export default Layout;
