import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    display: 'block',
    width: '100%',
    padding: '2em',
  },
  paperBody: {
    marginTop: '2em',
  },
  logo: {
    width: '300px',
    marginBottom: '3em'
  }
});

const TMPaper = ({ children, title, logoPath, ...props }) => {
  const { root, paper, paperBody, logo } = useStyles();

  return (
    <div className={root}>
      <Paper className={paper} {...props}>
        { logoPath && <img src={logoPath} alt="logo" className={logo}/> }
        {title && (
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        )}

        <div className={paperBody}>{children}</div>
      </Paper>
    </div>
  );
};

export default TMPaper;
