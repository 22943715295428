import React, { useState, useReducer, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import Icon from '@mdi/react'
import { mdiTrainCarContainer } from '@mdi/js';
import { mdiListBoxOutline } from '@mdi/js';
import { mdiPaperclip } from '@mdi/js';
import client from '../feathers';
import useIntl from '../hooks/useIntl';
import TMDataTable from '../lib/Table/TMDataTable';
import useAuth from '../hooks/useAuth';
import RequestForReleaseButton from '../components/request-for-release/RequestForReleaseButton';

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    alignContent: 'space-between'
  },
  ref: {
  	color: theme.palette.primary.dark,
  	marginLeft: '28px'
  },
  topAlign: {
  	'& td': {
  		verticalAlign: 'top',
  	}
  }
}));

export default function RequestForReleaseOverview() {
	
	const [state, dispatch] = useReducer(reducer, initialState);
  const { translate } = useIntl();
  const classes = useStyles();
  const { user } = useAuth();

  const fetchRequestsForRelease = async () => {
		const result = await client.service('requests-for-release').find({ query: { organizationAddress: user.organizationAddress }});
		console.log("results", result.data)
	  dispatch({ payload: { requests: result.data } })
  };

  useEffect(() => {
    fetchRequestsForRelease();
  }, []);

	const columns = [
		'Releases',
		'Carrier',
		'Attachments',
		'Date',
		{
      name: "Releases",
      options: { filter: true, download: false, display: false, viewColumns: false }
    }, 
	];

	const getData = () => {
		return state.requests.map(r => {
			return [
				r.inputJSON
					.filter(i => i.bl)
					.sort((a, b) => a.bl.localeCompare(b.bl))
					.concat(r.inputJSON
										.filter(i => i.ct)
										.sort((a, b) => a.ct.localeCompare(b.ct))
					)
					.map(i => i.ct 
						? <span className={classes.icon}><Icon path={mdiTrainCarContainer} size={1}/>&nbsp;{i.ct}</span> 
						: <span className={classes.icon}><Icon path={mdiListBoxOutline} size={1}/>&nbsp;{i.bl}</span>
					), 
				r.carrier.name,
				r.files.sort().map(f => {
					let fileReference = '';
					if (r.fileReferences) {
						const ref = r.fileReferences.find(ref => ref.name === f);
						if (ref && ref.hasOwnProperty('value')) {
							fileReference = <small className={classes.ref}>Ref: {ref.value}</small>
						}

					}
					return (
						<React.Fragment>
							<span className={classes.icon}>
								<Icon path={mdiPaperclip} size={1}/>&nbsp;{f}
							</span>
							{ fileReference }
						</React.Fragment>
					)
				}),
				<Moment format="ddd DD MMM YYYY hh:mm" date={r.createdAt}/>,
				// hidden fields for search & filter
				r.inputRaw
			]
		})
	}



	return (
		<TMDataTable
      title={'Your requests for releases'}
      columns={columns}
      data={getData()}
      setRowProps={() => ({className: classes.topAlign})}
      options={{
	      print: false,
	      download: false,
	      filter: false,
	      viewColumns: false,
	      customToolbar: () => <RequestForReleaseButton user={user} fetchRequestsForRelease={fetchRequestsForRelease}/>,
	      customSearch: (searchQuery, currentRow, columns) => {
        	let isFound = false;
        	currentRow.forEach(col => {
	          if (col.toString().indexOf(searchQuery) >= 0) {
	            isFound = true;
	          }
        	});
        	return isFound;
      	},
      }}
    />
	);
}

const initialState = {
  requests: [],
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState }
    default:
      // = SET
      return { ...state, ...payload }
  }
}
