const bulkOperationsTypes = {
  SET_UPLOADED_CONTAINERS: 'SET_UPLOADED_CONTAINERS',
  SET_ANON_ADDRESSES: 'SET_ANON_ADDRESSES',
  SET_FILTERED_CONTAINERS: 'SET_FILTERED_CONTAINERS',

  SET_TOTAL: 'SET_TOTAL',
  SET_ERROR: 'SET_ERROR',
  SET_TOTAL_ERRORS: 'SET_TOTAL_ERRORS',

  SET_TRANSFER_DIALOG: 'SET_TRANSFER_DIALOG',

  SET_FORM_VALUES: 'SET_FORM_VALUES',
  RESET_FORM: 'RESET_FORM',

  SET_SELECTED_RELEASES: 'SET_SELECTED_RELEASES',
  SET_WARNING: 'SET_WARNING',
};

export default bulkOperationsTypes;
